import { useEffect, useState } from 'react';
import closingCostsService from '../../api/services/closingCosts';
import { Skeleton, Typography } from '@mui/material';
import { formatCurrency } from '../../utils/formatCurrency';

interface Props {
  listingId: string;
  offerId: string;
}

interface ClosingCosts {
  costs: number;
  proceeds: number;
}

const Proceeds: React.FC<Props> = ({ listingId, offerId }) => {
  const [costs, setCosts] = useState<ClosingCosts | null>(null);

  const fetchClosingCost = async () => {
    const costs = await closingCostsService.find(listingId, offerId);
    setCosts(costs);
  };

  useEffect(() => {
    fetchClosingCost();
  }, []);

  if (!costs) {
    return <Skeleton />;
  }

  return (
    <Typography variant="body2">{formatCurrency(costs.proceeds)}</Typography>
  );
};

export default Proceeds;
