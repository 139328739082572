import {
  AppBar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LandingButtons from '../../../components/Drawer/toolbar/LandingButtons';
import WaitlistForm from '../WaitlistForm';
import Footer from '../../../components/Footer';
import {
  AllInclusive,
  Api,
  DesktopWindows,
  FlashOn,
  HomeWork,
  Lock,
  Public,
} from '@mui/icons-material';

const MLS: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const features = [
    {
      title: 'Included with Homepoint',
      description:
        'Get started without any upfront costs. Your access to Homepoint gives you access to our MLS.',
      icon: <HomeWork color="primary" />,
    },
    {
      title: 'Instant Sign Up',
      description:
        'Start using our services immediately after registration with instant verification for real estate professionals.',
      icon: <FlashOn color="primary" />,
    },
    {
      title: 'Modern, Easy to Use Interface',
      description:
        'Create, manage, and view listings with an intuitive and user-friendly interface designed for efficiency and ease of use.',
      icon: <DesktopWindows color="primary" />,
    },
    {
      title: 'Modern Scheduling and Lockboxes',
      description:
        'Rent to own or buy your lockboxes. Homepoint lockboxes are integrated with your scheduled showings. No app required for your visitors.',
      icon: <Lock color="primary" />,
    },
    // {
    //   title: "One Platform",
    //   description:
    //     "Client communication, agent research, transactions, offers, and showings are all in one platform.",
    //   icon: <AllInclusive color="primary" />,
    // },
    {
      title: 'Free Integration Access',
      description:
        'Seamlessly integrate with your existing systems, APIs, IDX, etc., with no extra fees.',
      icon: <Api color="primary" />,
    },
    {
      title: 'Wider Geographic Coverage',
      description: 'Our MLS is not restricted to local markets.',
      icon: <Public color="primary" />,
    },
  ];

  const FeatureCard = ({
    icon,
    title,
    description,
    image,
  }: {
    icon: any;
    title: string;
    description: string;
    image?: string;
  }) => (
    <>
      {image && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 24,
            height: 330,
          }}
        >
          <img
            src={image}
            style={{
              width: '100%',
              borderRadius: 8,
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          />
        </div>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon}
        <Typography
          variant="h6"
          component="div"
          sx={{ ml: 2, fontWeight: 700 }}
        >
          {title}
        </Typography>
      </Box>
      <Typography>{description}</Typography>
    </>
  );

  return (
    <>
      <AppBar elevation={3}>
        <Toolbar sx={{ backgroundColor: '#fff' }}>
          <LandingButtons />
        </Toolbar>
      </AppBar>
      <div
        style={{
          background:
            'linear-gradient(180deg, #63b8ff 0%, rgba(224, 222, 247, 0) 50%)',
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={2} p={2} pt={10} justifyContent={'center'}>
            <Typography
              variant="h4"
              textAlign={'center'}
              sx={{ fontWeight: 700 }}
            >
              Modern MLS
            </Typography>
            <br />
            <Typography variant="body1" gutterBottom textAlign={'center'}>
              In addition to integrating with your current MLS, Homepoint is
              launching a revolutionary Multiple Listing Service (MLS) to
              enhance your real estate experience. <b>Why the change?</b>
              <br />
              <br />
              Many existing MLS systems are user-unfriendly, outdated, and lack
              the insights and research tools essential for your critical tasks.
              Additionally, agents and brokers often face extra charges for
              setup, additional feeds, and data access. We believe this is both
              unnecessary and unfair to our users.
            </Typography>
            <Divider sx={{ opacity: 0.5, margin: 4 }} />
            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <Card>
                  <Stack
                    direction="column"
                    spacing={0.5}
                    p={2}
                    alignItems={'center'}
                  >
                    <Typography variant="h5" fontWeight={700}>
                      Ours
                    </Typography>
                    <Divider sx={{ opacity: 0.5, margin: 2, width: '100%' }} />
                    <Typography variant="body1">No monthly charges</Typography>
                    <Typography variant="body1">
                      No setup or renewal fees
                    </Typography>
                    <Typography variant="body1" align="center">
                      No external feed costs for IDX, VOW, etc.
                    </Typography>
                    <Typography variant="body1">No assistant fees</Typography>
                    <Typography variant="body1" align="center">
                      No association membership required
                    </Typography>
                    <Typography variant="body1" align="center">
                      Better accuracy and integrity monitoring
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid xs={12} sm={6}>
                <Card>
                  <Stack
                    direction="column"
                    spacing={0.5}
                    p={2}
                    alignItems={'center'}
                  >
                    <Typography variant="h5" fontWeight={700}>
                      Theirs
                    </Typography>
                    <Divider sx={{ opacity: 0.5, margin: 4, width: '100%' }} />
                    <Typography variant="body1">
                      Recurring fees for access
                    </Typography>
                    <Typography variant="body1">
                      Application fees and renewal fees
                    </Typography>
                    <Typography variant="body1" align="center">
                      Setup and recurring fees for IDX, VOW, etc.
                    </Typography>
                    <Typography variant="body1" align="center">
                      Application and recurring fees for assistants
                    </Typography>
                    <Typography variant="body1" align="center">
                      Association membership may be required
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
            <Divider sx={{ opacity: 0.5, margin: 4 }} />
            <Typography
              variant="h4"
              textAlign={'center'}
              sx={{ fontWeight: 700 }}
            >
              Features
            </Typography>
            <Box sx={{ flexGrow: 1, padding: 2 }}>
              <Grid container spacing={2}>
                {features.map((feature, index) => (
                  <Grid xs={12} sm={6} md={4} key={index}>
                    <FeatureCard
                      icon={feature.icon}
                      title={feature.title}
                      description={feature.description}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider sx={{ opacity: 0.5, margin: 4 }} />
            <WaitlistForm mobile={mobile} title="Learn More" />
          </Stack>
        </Container>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
};

export default MLS;
