import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

export type PricingOpinionWithListings = Prisma.pricingOpinionsGetPayload<{
  include: {
    listings: {
      include: {
        uploads: true;
      };
    };
  };
}>;

const pricingOpinionsService = {
  create: async (data: Omit<Prisma.pricingOpinionsCreateInput, 'user'>) => {
    const res = await axiosInstance.post('/pricing-opinions', data);
    return res.data;
  },
  get: async (id: string): Promise<PricingOpinionWithListings> => {
    const res = await axiosInstance.get(`/pricing-opinions/${id}`);
    return res.data;
  },
  patch: async (id: string, data: Prisma.pricingOpinionsUpdateInput) => {
    const res = await axiosInstance.patch(`/pricing-opinions/${id}`, data);
    return res.data;
  },
};

export default pricingOpinionsService;
