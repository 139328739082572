import { styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    color: 'inherit',
  },
  '&:visited': {
    color: 'inherit',
  },
  '&:focus': {
    color: 'inherit',
  },
}));
