import { Home } from '@mui/icons-material';
import {
  Typography,
  Stack,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import FormSVG from '/form_input.svg';
import ForSale from '/for_sale.svg';
import Review from '/review.svg';
import ClosingSVG from '/closing.svg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { styled } from '@mui/material/styles';

const ListItem = styled('li')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

const HIWBuying: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography
        variant="h4"
        textAlign={'center'}
        sx={{ fontWeight: 700, mt: 4 }}
      >
        How Buying Works
      </Typography>
      {/* <Typography variant="subtitle2" textAlign={'center'}>
        The experience of a traditional agent for a flat fee.
      </Typography> */}
      <Typography variant="subtitle1" textAlign={'center'}>
        Get support from a real estate agent at any time.
      </Typography>
      <Grid2 container>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 1</Typography>
            <Typography variant="h5" fontWeight={600}>
              Explore Homes with Flexibility 🔍
            </Typography>
          </Stack>
          <Typography>
            <ul>
              <ListItem>
                Find homes you love on sites like Zillow or Realtor.com.
              </ListItem>
              <ListItem>
                Schedule tours by entering the home&apos;s address and picking a
                time that works for you.
              </ListItem>
              <ListItem>
                Tours are $50 per visit, fully refunded if you buy a home with
                us.
              </ListItem>
              <ListItem>
                No commitments required-explore as many homes as you like!
              </ListItem>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={ForSale} height={350} />
            </Grid2>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={FormSVG} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 2</Typography>
            <Typography variant="h5" fontWeight={600}>
              Make Offers with Confidence 💪
            </Typography>
          </Stack>
          <Typography>
            <ul>
              <ListItem>Prepare your offer with easy, guided steps.</ListItem>
              <ListItem>
                Get expert feedback with a review of your offer by
                Homepoint&apos;s agents.
              </ListItem>
              <ListItem>
                Submit with confidence after consulting on all offer details
                before it goes to the seller.
              </ListItem>
            </ul>
          </Typography>
        </Grid2>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 3</Typography>
            <Typography variant="h5" fontWeight={600}>
              Navigate the Transaction Process 🚀
            </Typography>
          </Stack>
          <Typography>
            <ul>
              <ListItem>
                Stay organized using your dashboard to track key milestones.
              </ListItem>
              <ListItem>
                Effortlessly coordinate vendors by booking and managing them
                directly through the platform.
              </ListItem>
              <ListItem>
                Get real-time updates and support from our team to clarify
                documents and steps.
              </ListItem>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={mobile ? 0 : 6} textAlign={'center'}>
              <img src={Review} height={350} />
            </Grid2>
            <Grid2 xs={mobile ? 0 : 6} textAlign={'center'}>
              <img src={ClosingSVG} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 4</Typography>
            <Typography variant="h5" fontWeight={600}>
              Close and Celebrate! 🎉
            </Typography>
          </Stack>
          <Typography>
            <ul>
              <ListItem>
                Homepoint will guide you through reviewing all closing documents
                to ensure accuracy and readiness
              </ListItem>
              <ListItem>
                Schedule a final walk-through of your new home to ensure
                it&apos;s in the expected condition before closing
              </ListItem>
              <ListItem>
                Schedule a closing appointment with a closing attorney at a
                convenient time and place.
              </ListItem>
            </ul>
          </Typography>
        </Grid2>
      </Grid2>
    </>
  );
};

export default HIWBuying;
