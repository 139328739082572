import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { WrappedApp } from './App';

import * as Sentry from '@sentry/react';

Sentry.init({
  // eslint-disable-next-line max-len
  dsn: 'https://89c1f7a4e1ea3cd5a9605a71bc102e21@o4507715780870144.ingest.us.sentry.io/4507715782115328',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.6,
  tracePropagationTargets: ['localhost', /^https:\/\/api\.homepoint\.co/],
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <WrappedApp />
  </Provider>
);
