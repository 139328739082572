import { Box } from '@mui/material';

const Circle: React.FC<{ size: number; color: string }> = ({ size, color }) => {
  return (
    <Box
      sx={{
        minWidth: size,
        minHeight: size,
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
      }}
    />
  );
};

export default Circle;
