import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

const notificationsService = {
  find: async (params?: Prisma.notificationsFindManyArgs) => {
    const res = await axiosInstance.get('/notifications', { params });
    return res.data;
  },
  patch: async (data: Prisma.notificationsUncheckedUpdateInput) => {
    const res = await axiosInstance.patch('/notifications', data);
    return res.data;
  },
};

export default notificationsService;
