import { Grid, List, ListItem, Typography } from '@mui/material';

const BuyingPower = () => {
  return (
    <>
      <Grid container gap={2} direction={'column'}>
        <Grid xs={12} textAlign={'center'}>
          <Typography variant="h4" gutterBottom>
            Unleash Your Buying Power with Homepoint
          </Typography>

          <Typography variant="body1" paragraph>
            In today&apos;s competitive real estate market, having strong{' '}
            <b>buying power</b> is key to securing the home of your dreams.
            Homepoint offers a unique way to boost your buying power through its
            flat fee model, helping you save money and giving your offer an edge
            over others. But what exactly does &quot;buying power&quot; mean at
            Homepoint, and how does it benefit both buyers and sellers?
          </Typography>

          <Typography variant="h5" gutterBottom>
            What Is Buying Power?
          </Typography>

          <Typography variant="body1" paragraph>
            <b>Buying Power</b> at Homepoint refers to the extra money you save
            by using Homepoint&apos;s flat fee model, as opposed to the
            traditional 3% agent commission. Because sellers typically pay
            commissions in real estate transactions, the lower fees with
            Homepoint allow sellers to keep more money, while giving buyers the
            flexibility to strengthen their offers. By reducing commission
            costs, both buyers and sellers benefit from a more competitive and
            financially advantageous process.
          </Typography>

          <Typography variant="h5" gutterBottom>
            How Does Homepoint Boost Your Buying Power?
          </Typography>

          <Typography variant="h6" gutterBottom>
            1. Flat Fee Savings
          </Typography>

          <Typography variant="body1" paragraph>
            In a typical real estate transaction, the seller pays a 3%
            commission to the buyer&apos;s agent. This means the seller takes
            home less money from the sale. With Homepoint&apos;s flat fee model
            of <b>$2,500</b>, the seller keeps more of their earnings, making
            your offer more attractive to them.
          </Typography>

          <Typography variant="body1" paragraph>
            Let&apos;s break it down with a <b>numerical example</b>:
          </Typography>

          <List>
            <ListItem>
              <Typography variant="body1">
                Suppose you&apos;re buying a home listed at <b>$400,000</b>.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                With a traditional 3% buyer&apos;s agent commission, the seller
                would pay <b>$12,000</b> in commission fees.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                However, with Homepoint&apos;s flat fee of <b>$2,500</b>, the
                seller saves <b>$9,500</b>.
              </Typography>
            </ListItem>
          </List>

          <Typography variant="body1" paragraph>
            This savings not only benefits the seller but also makes your offer
            more appealing since the seller keeps more money from the
            transaction. You, as the buyer, can also use this to your advantage
            by offering better terms or increasing your offer without the burden
            of high commission fees.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. More Competitive Offers
          </Typography>

          <Typography variant="body1" paragraph>
            With Homepoint&apos;s flat fee model, your offer can stand out in a
            competitive market. Here&apos;s how:
          </Typography>

          <Typography variant="body1" paragraph>
            If the seller is paying less in commission, they&apos;re more likely
            to accept an offer that leaves them with more net proceeds from the
            sale. The extra <b>$9,500</b> saved in commission could allow the
            seller to accept your offer over another buyer&apos;s offer tied to
            higher commission fees.
          </Typography>

          <Typography variant="body1" paragraph>
            You can also use this flexibility to structure a stronger offer. For
            example, with the <b>$9,500</b> saved, you could:
          </Typography>

          <List>
            <ListItem>
              <Typography variant="body1">
                Offer <b>$409,500</b> instead of <b>$400,000</b>, making your
                bid more attractive to the seller, or
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                Keep your offer at <b>$400,000</b> and use the savings for
                closing costs or home improvements.
              </Typography>
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom>
            3. Transparency and Control
          </Typography>

          <Typography variant="body1" paragraph>
            Homepoint&apos;s platform provides clear, upfront pricing, so there
            are no surprises for either the buyer or the seller. Both parties
            understand how much money they&apos;re saving, enabling smarter
            decisions about the transaction. This transparency enhances your
            buying power by giving you full control over your financial
            strategy.
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. Expert Guidance Without the High Commission
          </Typography>

          <Typography variant="body1" paragraph>
            With Homepoint, you still receive expert real estate guidance,
            including pricing advice, negotiation strategies, and offer
            consultation, without the hefty 3% commission fee. Both buyers and
            sellers benefit from professional support while keeping more money
            in their pockets.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Why Homepoint&apos;s Buying Power Matters for Buyers and Sellers
          </Typography>

          <Typography variant="body1" paragraph>
            In a competitive housing market, buyers often compete against
            multiple offers, and sellers want to maximize their profits.
            Homepoint&apos;s flat fee model enhances your <b>buying power</b> by
            allowing you to structure a stronger offer, while the seller enjoys
            the benefit of keeping more money from the sale.
          </Typography>

          <Typography variant="body1" paragraph>
            By leveraging the savings from Homepoint&apos;s flat fee model,
            buyers can make more competitive offers without increasing financial
            strain, and sellers can keep more of their hard-earned money.
            It&apos;s a win-win for both parties.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Start your home-buying journey with Homepoint and unleash your{' '}
            <b>buying power</b> today!
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
};

export default BuyingPower;
