export function formatCurrency(
  value: number,
  locale = 'en-US',
  currency = 'USD'
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  }).format(value);
}
