import { formatCurrency } from '../../../../utils/formatCurrency';
import { comps } from '@prisma/client';
import {
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DeleteOutline,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNewOutlined,
} from '@mui/icons-material';
import { sortBy } from 'lodash';
import Circle from '../../../../components/Circle/Circle';
import PhotoCarouselV2 from './PhotoCarouselV2';
import compsService from '../../../../api/services/comps';

interface Props {
  comps: comps[];
  showDelete?: boolean;
  onDelete?: () => void;
}

const getDateSoldText = (status: number, dateSold?: Date) => {
  if (status !== 0) {
    return status === 1 ? 'Currently For Sale' : 'Pending';
  }
  return new Date(dateSold ?? new Date()).toLocaleDateString();
};

const Row: React.FC<{
  comp: comps;
  showDelete?: boolean;
  onDelete?: () => void;
}> = (props) => {
  const { comp, showDelete = false, onDelete } = props;
  const [open, setOpen] = React.useState(false);
  const [photos, setPhotos] = useState<string[] | null>(null);

  const getCompPhotos = async () => {
    const res = await compsService.get(comp.id);
    setPhotos(res.photos);
  };

  const deleteComp = async () => {
    await compsService.remove(comp.id);
    onDelete?.();
  };

  useEffect(() => {
    getCompPhotos();
  }, []);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <Stack direction="row" gap={1} justifyContent="flex-end">
            {!photos && <CircularProgress size={20} />}
            {photos && photos.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
            <IconButton
              size="small"
              onClick={() =>
                window.open(
                  // eslint-disable-next-line max-len
                  `https://www.google.com/search?q=${comp.address}`,
                  '_blank'
                )
              }
            >
              <OpenInNewOutlined />
            </IconButton>
          </Stack>
        </TableCell>
        <TableCell component="th" scope="row">
          {comp.address}
        </TableCell>
        <TableCell>{comp.beds}</TableCell>
        <TableCell>{comp.baths}</TableCell>
        <TableCell>{comp.area}</TableCell>
        <TableCell>{formatCurrency(comp.price)}</TableCell>
        <TableCell>{formatCurrency(comp.price / comp.area)}</TableCell>
        <TableCell>{comp.dom}</TableCell>
        <TableCell>{getDateSoldText(comp.isActive, comp.dateSold)}</TableCell>
        <TableCell align="center">
          {comp.isActive !== 0 && (
            <Circle
              size={15}
              color={comp.isActive === 1 ? 'success.main' : 'warning.main'}
            />
          )}
        </TableCell>
        {showDelete && (
          <TableCell align="center">
            <IconButton size="small" onClick={() => deleteComp()}>
              <DeleteOutline />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, width: 100, maxWidth: 100 }}
          colSpan={10}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {photos && photos.length > 0 && <PhotoCarouselV2 photos={photos} />}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const calculateSimilarity = (
  subject: Pick<comps, 'baths' | 'beds' | 'area'>,
  comparable: Omit<comps, 'id'>
): number => {
  // Weights for each feature
  const weightSqFt = 0.7;
  const weightBedrooms = 0.25;
  const weightBathrooms = 0.25;

  const subjectSq = subject.area;
  const compSq = comparable.area;
  // Similarity calculations
  const similaritySqFt =
    1 - Math.abs(subjectSq - compSq) / Math.max(subjectSq, compSq);
  const similarityBedrooms = subject.beds === comparable.beds ? 1 : 0;
  const similarityBathrooms = subject.baths === comparable.baths ? 1 : 0;

  // Weighted similarity
  const similarityPercentage =
    weightSqFt * similaritySqFt +
    weightBedrooms * similarityBedrooms +
    weightBathrooms * similarityBathrooms;
  return similarityPercentage * 100;
};

const CompsTable: React.FC<Props> = ({
  comps,
  showDelete = false,
  onDelete,
}) => {
  const activeComps = comps.filter(
    (comp) => comp.isActive === 1 || comp.isActive === 2
  );
  const nonActiveComps = comps.filter((comp) => comp.isActive === 0);
  const sortedComps = [
    ...activeComps,
    ...sortBy(nonActiveComps, 'dateSold').reverse(),
  ];

  return (
    <>
      <Typography fontWeight={600}>Comparable Properties</Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width={50} />
              <TableCell>Address</TableCell>
              <TableCell width={50}>Beds</TableCell>
              <TableCell width={50}>Baths</TableCell>
              <TableCell>Sq Ft</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>$ / sq ft</TableCell>
              <TableCell>Days on Market</TableCell>
              <TableCell>Date Sold</TableCell>
              <TableCell width={25}>Active</TableCell>
              {showDelete && <TableCell width={25} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedComps.map((comp: comps) => (
              <Row
                key={comp.id}
                comp={comp}
                showDelete={showDelete}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CompsTable;
