import axiosInstance from '../api';

export interface Rates {
  id: number;
  rate: number;
  term: string;
  downloaded_at: Date;
}

const ratesService = {
  find: async (): Promise<Rates[]> => {
    const response = await axiosInstance.get('/rates', {});
    return response.data;
  },
};

export default ratesService;
