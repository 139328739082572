import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import listingsService, {
  listingsWithUploads,
} from '../../api/services/listings';
import { CircularProgress } from '@mui/material';

const ShortURLRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState<listingsWithUploads | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchListing = async () => {
    const listing = await listingsService.find({
      params: {
        where: {
          shortId: id,
        },
      },
    });
    if (listing.length > 0) {
      setListing(listing[0]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListing();
  }, [id]);

  useEffect(() => {
    if (listing) {
      navigate(`/listings/${listing.id}`);
    }
  }, [listing]);

  if (loading) {
    return <CircularProgress />;
  }

  return <></>;
};

export default ShortURLRedirect;
