import React, { useEffect, useState } from 'react'; // Add useState and useEffect
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import LandingButtons from '../components/Drawer/toolbar/LandingButtons';
import Footer from '../components/Footer';
import Intercom from '@intercom/messenger-js-sdk';

const PublicLayout: React.FC = () => {
  const [isSticky, setIsSticky] = useState(false); // State to track sticky status
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScroll = () => {
    const sticky = window.scrollY > 0 && isMobile;
    setIsSticky(sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll); // Add scroll event listener
    return () => {
      window.removeEventListener('scroll', handleScroll); // Clean up on unmount
    };
  }, []);

  Intercom({
    app_id: 'c60t2vfp',
  });

  return (
    <>
      <div
        style={{
          background: '#f6f9fb',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {/* <Box
        sx={{ width: '100%', backgroundColor: 'primary.main' }}>
          <Typography p={1} color='white' fontWeight={500} textAlign={'center'}>
            Have questions? Click here to chat with us
          </Typography>
        </Box> */}
        <Box
          sx={{
            p: 1,
            maxWidth: 1200,
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            flex: 1,
          }}
        >
          <LandingButtons />
          <Grid container>
            <Grid xs={12} pt={2}>
              <Outlet />
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </div>
    </>
  );
};

export default PublicLayout;
