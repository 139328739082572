import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import autocompleteService from '../../api/services/autocomplete';
import { AddressSelection } from '../../types/AddressSelection';

const DEBOUNCE_DELAY = 0; // Delay in milliseconds

interface Props {
  handleChange: (e: any) => void;
  handleKeyDown?: (e: any) => void;
  placeholder?: string;
  size?: 'small' | 'medium';
  property: AddressSelection | null;
  allowCities?: boolean;
}

const AutoComplete: React.FC<Props> = ({
  handleChange,
  handleKeyDown,
  placeholder,
  size,
  property,
  allowCities,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(property?.address || '');
  const [loading, setLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    if (property === null) {
      setInputValue('');
    }
  }, [property]);

  let placeholderStr = 'Address';
  if (placeholder) {
    placeholderStr = placeholder;
  }

  useEffect(() => {
    if (inputValue.length < 3) {
      setOptions([]);
      setLoading(false);
      return;
    }

    if (options.length === 1) {
      setLoading(false);
      return;
    }

    setLoading(true);
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(async () => {
      try {
        const res = await autocompleteService.find(inputValue);
        const data = res.data
          .filter((opt: any) => {
            if (allowCities) {
              return opt.searchType === 'A' || opt.searchType === 'C';
            }
            return opt.searchType === 'A';
          })
          .map((opt: any) => ({
            ...opt,
            address: opt.address?.replace(/\s+/g, ' ').trim(),
          }));
        setOptions(data);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }, DEBOUNCE_DELAY);
    setDebounceTimeout(timeout);
    handleChange(options.find((option: any) => option.address === inputValue));
    return () => clearTimeout(timeout); // Cleanup on component unmount or input change
  }, [inputValue]);

  return (
    <Autocomplete
      freeSolo
      fullWidth
      inputValue={inputValue}
      options={options}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(e, value) => {
        handleChange(value);
      }}
      onKeyDown={handleKeyDown}
      getOptionLabel={(option: any) => option.title || ''}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
      filterOptions={(x) => x} // Disable built-in filtering
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholderStr}
          variant="outlined"
          size={size}
          sx={{
            '& fieldset': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          data-testid="address-autocomplete"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoComplete;
