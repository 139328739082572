import { HomeOutlined, ListAlt } from '@mui/icons-material';
import { IRoute } from '../interfaces';
import MLS from '../pages/Landings/Agents/MLS';
import LandingV2 from '../pages/Landings/LandingV2';
import Listings from '../pages/Listings/Listings';
import FAQ from '../pages/Landings/components/FAQ';
import About from '../pages/Landings/About';
import NAR from '../pages/Landings/Blog/NAR';
import Policies from '../pages/Landings/Policies';
import Press from '../pages/Landings/Press';
import Product from '../pages/Landings/Product';
import BuyingPower from '../pages/Landings/Blog/BuyingPower';
import NotFound from '../pages/NotFound';

export const routes: Array<IRoute> = [
  {
    key: 'agents-landing-route',
    title: 'Homepoint - Sell your home for less',
    path: '/',
    enabled: true,
    component: LandingV2,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'mls-landing-route',
    title: 'A Modern MLS',
    path: '/mls',
    enabled: true,
    component: MLS,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'listings-route',
    title: 'Listing',
    path: '/listings',
    enabled: true,
    component: Listings,
    icon: ListAlt,
    menu: false,
    protected: false,
  },
  {
    key: 'faqs-route',
    title: 'FAQs',
    path: '/faqs',
    enabled: true,
    component: FAQ,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'about-route',
    title: 'About Us',
    path: '/aboutus',
    enabled: true,
    component: About,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'policies-route',
    title: 'Policies',
    path: '/policies',
    enabled: true,
    component: Policies,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'press-route',
    title: 'Press',
    path: '/press',
    enabled: true,
    component: Press,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'product-route',
    title: 'Product',
    path: '/product',
    enabled: true,
    component: Product,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'nar-route',
    title: 'NAR',
    path: '/nar',
    enabled: true,
    component: NAR,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'buying-power-route',
    title: 'Buying Power',
    path: '/buyingpower',
    enabled: true,
    component: BuyingPower,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'not-found',
    path: '/404',
    component: NotFound,
    icon: HomeOutlined,
    title: '404',
    enabled: true,
    menu: false,
    protected: false,
  },
];
