import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

const messagesService = {
  find: async () => {
    const res = await axiosInstance.get('/messages');
    return res.data;
  },
  post: async (
    data: Prisma.messagesCreateWithoutSenderInput | Prisma.messagesCreateInput
  ) => {
    const res = await axiosInstance.post('/messages', data);
    return res.data;
  },
  patch: async (data: Prisma.messagesUpdateManyArgs) => {
    const res = await axiosInstance.patch('/messages', data);
    return res.data;
  },
};

export default messagesService;
