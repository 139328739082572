import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prisma } from '@prisma/client';
import notificationsService from '../../api/services/notifications';

interface State {
  notifications: Prisma.notificationsGetPayload<{ include: { user: true } }>[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  notifications: [],
  loading: false,
  error: null,
};

export const fetchNotes = createAsyncThunk(
  'notifications/fetch',
  async (_params, { rejectWithValue }) => {
    try {
      const notes = await notificationsService.find();
      return notes;
    } catch (error) {
      return rejectWithValue('Failed to fetch notifications');
    }
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchNotes.fulfilled,
        (
          state,
          action: PayloadAction<
            Prisma.notificationsGetPayload<{ include: { user: true } }>[]
          >
        ) => {
          state.loading = false;
          state.notifications = action.payload;
        }
      )
      .addCase(fetchNotes.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default notificationsSlice.reducer;
