import axios from 'axios';
import { useEffect, useState } from 'react';

interface Props {
  address: string;
  height: string;
}

const StreetViewImage: React.FC<Props> = ({ address, height }) => {
  const [listingImage, setListingImags] = useState('');
  const urlAddress = encodeURIComponent(address);
  // eslint-disable-next-line max-len
  const formattedAddress = `https://maps.googleapis.com/maps/api/streetview?size=600x300&location=${urlAddress}&key=AIzaSyDIRWTPcy-0FWGza8RzhWRdSq4FK57rhKY`;

  return <img src={formattedAddress} height={height} width={'100%'} />;
};

export default StreetViewImage;
