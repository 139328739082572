import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { groupBy } from 'lodash';

export const selectOffers = (state: RootState) => state.offers.offers;

export const selectBuyingOffers = createSelector([selectOffers], (offers) =>
  offers.filter((offer) => offer.listings.type === 'BUYER')
);

export const selectSellingOffers = createSelector([selectOffers], (offers) =>
  offers.filter((offer) => offer.listings.type === 'SELLER')
);

export const selectActiveSellingOffers = createSelector(
  [selectSellingOffers],
  (offers) =>
    offers.filter(
      (offer) => offer.status === 'PENDING' || offer.status === 'ACCEPTED'
    )
);

export const selectPendingBuyingOffers = createSelector(
  [selectBuyingOffers],
  (offers) => offers.filter((offer) => offer.status === 'PENDING')
);

export const selectPendingSellingOffers = createSelector(
  [selectSellingOffers],
  (offers) => offers.filter((offer) => offer.status === 'PENDING')
);

export const selectOffersGroupedByBuyer = createSelector(
  [selectSellingOffers],
  (offers) => {
    return groupBy(offers, 'userId');
  }
);
