import { Chip, Stack, Typography } from '@mui/material';
import { ListingStatus as LS } from '@prisma/client';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { lighten, PaletteColor } from '@mui/material/styles';
import { useState } from 'react';
import useIsMobile from '../../hooks/useIsMobile';

interface Props {
  status: LS | null;
}

const getTextForStatus = (status: LS | null) => {
  switch (status) {
    case 'PENDING':
      return 'Pending';
    case 'APPROVED':
      return 'Approved';
    case 'DRAFT':
      return 'Draft';
  }
};

const getTooltipTextForStatus = (status: LS | null) => {
  switch (status) {
    case 'PENDING':
      // eslint-disable-next-line max-len
      return 'The listing is pending approval by Homepoint. Your listing will stay in this status until pricing, photos, and other details are completed.';
    case 'APPROVED':
      return 'The listing is approved by Homepoint.';
    case 'DRAFT':
      return 'The listing is a draft.';
  }
};

const getColorForStatus = (status: LS | null) => {
  switch (status) {
    case 'PENDING':
      return 'warning';
    case 'APPROVED':
      return 'success';
    case 'DRAFT':
      return 'primary';
  }
};

const ListingStatus: React.FC<Props> = ({ status }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const isMobile = useIsMobile();
  if (!status) return null;

  return (
    <Stack direction="row" spacing={1}>
      <CustomTooltip
        title={
          <Typography variant="body2">
            {getTooltipTextForStatus(status)}
          </Typography>
        }
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        disableTouchListener={isMobile}
      >
        <Chip
          label={
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="caption" fontWeight={600}>
                {getTextForStatus(status)}
              </Typography>
            </Stack>
          }
          clickable={false}
          onClick={() => setShowTooltip(!showTooltip)}
          sx={{
            backgroundColor: (theme) => {
              const color = getColorForStatus(status);
              return lighten(
                (
                  theme.palette[
                    color as keyof typeof theme.palette
                  ] as PaletteColor
                ).main,
                0.4
              );
            },
          }}
        />
      </CustomTooltip>
    </Stack>
  );
};

export default ListingStatus;
