import axiosInstance from '../api';

const closingCostsService = {
  find: async (listingId: string, offerId?: string, price?: number) => {
    const res = await axiosInstance.get('/closing-costs', {
      params: {
        listingId,
        offerId,
        price,
      },
    });
    return res.data;
  },
};

export default closingCostsService;
