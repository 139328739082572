import {
  Stack,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { CheckCircleOutlineRounded, InfoOutlined } from '@mui/icons-material';
import { DocusealForm } from '@docuseal/react';
import { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import formsService from '../../api/services/forms';
import LoadingButton from '@mui/lab/LoadingButton';
import { offers } from '@prisma/client';

interface Props {
  formik: any;
  offer: offers;
}

const Disclosures: React.FC<Props> = ({ formik, offer }) => {
  const LEAD_FORM_ID = 4;
  const SELLER_CLOSING_COST = 3;

  const leadSigned = formik.values.leadSigned;
  const costsSigned = formik.values.costsSigned;
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState('');
  const [formId, setFormId] = useState<number | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (formId === LEAD_FORM_ID) {
      formik.setFieldValue('leadSigned', null);
    }
    if (formId === SELLER_CLOSING_COST) {
      formik.setFieldValue('costsSigned', null);
    }
  }, [slug]);

  useEffect(() => {
    if (formId) {
      getLeadForm(formId);
    } else {
      setSlug('');
    }
  }, [formId]);

  const getLeadForm = async (formId: number) => {
    setLoading(true);
    const res = await formsService.post({
      listingId: offer.listingId,
      leadPaint: formik.values.leadBasedPaint === 'yes',
      leadPaintExplain: formik.values.leadExplain,
      formId,
      offerId: offer.id,
    });
    setLoading(false);
    setSlug(res);
  };

  if (slug !== '') {
    return (
      <DocusealForm
        src={`https://docuseal.co/s/${slug}`}
        email="trey@homepoint.co"
        onComplete={(data) => {
          if (formId === LEAD_FORM_ID) {
            formik.setFieldValue('leadSigned', true);
          }
          if (formId === SELLER_CLOSING_COST) {
            formik.setFieldValue('costsSigned', true);
          }
          setFormId(null);
        }}
        expand={false}
        withFieldNames={false}
        values={{
          agentInitials: 'HP',
          sellerKnown: true,
          sellerNotKnown: formik.values.leadBasedPaint === 'no',
          sellerExplain: formik.values.leadExplain,
          sellerDate: format(new Date(), 'MM/dd/yyyy'),
        }}
        readonlyFields={['agentInitials', 'sellerExplain', 'sellerDate']}
        withTitle={false}
        signature={`${currentUser?.firstName} ${currentUser?.lastName}`}
      />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack direction="column" spacing={0}>
        <Grid container spacing={2}>
          {!leadSigned && (
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                error={Boolean(formik.errors.leadBasedPaint)}
              >
                <FormLabel component="legend">
                  Is there any <i>known</i> lead-based paint in the home?
                </FormLabel>
                <RadioGroup
                  aria-label="leadBasedPaint"
                  name="leadBasedPaint"
                  value={formik.values.leadBasedPaint}
                  onChange={formik.handleChange}
                  row
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                {formik.errors.leadBasedPaint && (
                  <Typography color="error" variant="body2">
                    {formik.errors.leadBasedPaint}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          )}
          {formik.values.leadBasedPaint === 'yes' && !leadSigned && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="leadExplain"
                name="leadExplain"
                placeholder="Where is the paint located, how many rooms, etc."
                rows={4}
                value={formik.values.leadExplain}
                onChange={formik.handleChange}
                error={
                  formik.touched.leadExplain &&
                  Boolean(formik.errors.leadExplain)
                }
                helperText={
                  formik.touched.leadExplain && formik.errors.leadExplain
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (!leadSigned) {
                  setFormId(LEAD_FORM_ID);
                }
              }}
              loading={loading}
              disabled={!formik.values.leadBasedPaint}
              color={leadSigned ? 'success' : 'primary'}
            >
              Sign Lead Paint Disclosure{' '}
              {leadSigned ? (
                <>
                  <CheckCircleOutlineRounded sx={{ ml: 1 }} />
                </>
              ) : (
                ''
              )}
            </LoadingButton>
            <Typography variant="body2" color="error" pt={1}>
              {formik.touched.leadSigned && formik.errors.leadSigned}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              onClick={() => {
                if (!costsSigned) {
                  setFormId(SELLER_CLOSING_COST);
                }
              }}
              loading={loading}
              color={costsSigned ? 'success' : 'primary'}
            >
              Sign Estimated Closing Costs{' '}
              {leadSigned ? (
                <>
                  <CheckCircleOutlineRounded sx={{ ml: 1 }} />
                </>
              ) : (
                ''
              )}
            </LoadingButton>
            <Typography variant="body2" color="error" pt={1}>
              {formik.touched.costsSigned && formik.errors.costsSigned}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} pb={1}>
              <Typography fontWeight={500}>Disclosures</Typography>
              <CustomTooltip
                width={'300'}
                placement="right"
                title={
                  <>
                    <Typography variant="body2">
                      Upfront disclosures are optional, but we recommend
                      disclosing any item that meets the critia below.
                    </Typography>
                    <ul>
                      <li>
                        Structural Issues: Any problems with the foundation,
                        roof, walls, or other structural components.
                      </li>
                      <li>
                        Water Damage: History of flooding, leaks, or water
                        damage.
                      </li>
                      <li>
                        Pest Infestations: Evidence of termites or other pest
                        problems.
                      </li>
                      <li>
                        Electrical and Plumbing Systems: Issues with wiring,
                        pipes, or major systems.
                      </li>
                      <li>
                        Environmental Hazards: Presence of lead-based paint,
                        asbestos, or radon.
                      </li>
                      <li>
                        Legal Issues: Any disputes, liens, or zoning violations
                        affecting the property.
                      </li>
                      <li>
                        Renovations and Repairs: Details of significant repairs
                        or renovations, including any that may have been
                        performed without proper permits.
                      </li>
                    </ul>
                  </>
                }
              >
                <InfoOutlined />
              </CustomTooltip>
            </Stack>
            <TextField
              fullWidth
              id="disclosureDescription"
              name="disclosureDescription"
              placeholder="None"
              multiline
              rows={2}
              value={formik.values.disclosureDescription}
              onChange={formik.handleChange}
              error={
                formik.touched.disclosureDescription &&
                Boolean(formik.errors.disclosureDescription)
              }
              helperText={
                formik.touched.disclosureDescription &&
                formik.errors.disclosureDescription
              }
            />
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
};

export default Disclosures;
