import axiosInstance from '../api';
import { newsletters } from '@prisma/client';

const newslettersService = {
  post: async ({ email }: { email: string }): Promise<newsletters> => {
    const response = await axiosInstance.post('/newsletters', { email });
    return response.data;
  },
};

export default newslettersService;
