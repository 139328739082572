/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Box, Card, Divider, Stack, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { BedOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ShowerTub from '../../components/svg/ShowerTub';
import SquareArea from '../../components/svg/SquareArea';
import listingsService, {
  listingsWithUploads,
} from '../../api/services/listings';

function formatCurrency(value: number, locale = 'en-US', currency = 'USD') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  }).format(value);
}

const TypographyAligned = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
}));

const Listings: React.FC = () => {
  const [listings, setListings] = useState<listingsWithUploads[]>([]);

  const fetchListings = async () => {
    const res = await listingsService.find({
      params: {
        where: {
          status: 'APPROVED',
        },
      },
    });
    setListings(res);
  };

  useEffect(() => {
    fetchListings();
  }, []);

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography sx={{ fontWeight: 600, fontSize: 24 }}>
        Homes for Sale
      </Typography>
      <Grid
        container
        spacing={0}
        rowGap={2}
        columnGap={2}
        sx={{ width: '100%' }}
      >
        {listings.map((listing) => {
          const mainPhoto = listing.uploads?.find(
            (upload) => upload.type === 'photo' && upload.main
          );

          return (
            <Grid xs={12} sm={4} key={listing.id}>
              <Link
                to={`/listings/${listing.id}`}
                style={{ textDecoration: 'none' }}
                key={listing.id}
              >
                <Card
                  sx={{
                    borderRadius: 4,
                    border: '1.5px solid #F0EFFB',
                  }}
                  elevation={1}
                >
                  <Stack direction="column">
                    <img
                      src={mainPhoto?.url ?? ''}
                      width={'100%'}
                      alt={listing.address}
                    />
                    <Box padding={1} pt={0}>
                      <Stack direction={'column'} pt={1}>
                        <Grid container>
                          <Grid xs={6}>
                            <Typography
                              color="secondary"
                              fontWeight={800}
                              fontSize={24}
                            >
                              {formatCurrency(listing.price)}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography fontSize={14}>
                          {listing.address}, {listing.city}, {listing.state}{' '}
                          {listing.zip}
                        </Typography>
                        <Divider sx={{ paddingTop: 1 }} />
                        <Stack
                          direction={'row'}
                          justifyContent={'space-evenly'}
                          pt={1}
                        >
                          <TypographyAligned>
                            <BedOutlined
                              color="secondary"
                              sx={{ marginRight: '8px' }}
                            />
                            {listing.beds} Bedrooms
                          </TypographyAligned>
                          <TypographyAligned>
                            <ShowerTub
                              size={20}
                              sx={{ marginRight: '4px' }}
                              color="#2f3640"
                            />
                            {listing.baths + listing.halfBaths / 2} Bathrooms
                          </TypographyAligned>
                          <TypographyAligned>
                            <SquareArea
                              size={20}
                              sx={{ marginRight: '8px' }}
                              color="#2f3640"
                            />
                            {listing.area} ft²
                          </TypographyAligned>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default Listings;
