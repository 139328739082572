import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prisma } from '@prisma/client';
import offersService from '../../api/services/offers';

export type offersWithUser = Prisma.offersGetPayload<{
  include: { user: true };
}>;

export type offersWithUserAndListing = Prisma.offersGetPayload<{
  include: { user: true; listings: true };
}>;

interface State {
  offers: offersWithUserAndListing[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  offers: [],
  loading: false,
  error: null,
};

export const fetchOffers = createAsyncThunk(
  'offers/fetch',
  async (_params, { rejectWithValue }) => {
    try {
      const clients = await offersService.find();
      return clients;
    } catch (error) {
      return rejectWithValue('Failed to fetch offers');
    }
  }
);

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchOffers.fulfilled,
        (state, action: PayloadAction<offersWithUserAndListing[]>) => {
          state.loading = false;
          state.offers = action.payload;
        }
      )
      .addCase(fetchOffers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default offersSlice.reducer;
