import NiceModal, { create, useModal } from '@ebay/nice-modal-react';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Card,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel as MuiFormControlLabel,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { listings } from '@prisma/client';
import { formatCurrency } from '../../utils/formatCurrency';
import { useState } from 'react';
import listingsService from '../../api/services/listings';
import { useAppDispatch } from '../../store/store';
import { fetchListings } from '../../store/slices/listings';

interface AdjustPriceModalProps {
  listing: listings;
}

const createPriceOptions = (listing: listings) => {
  const priceOptions = [];
  // create three price options doing 1.5%, 3%, and 4.5% off the current price
  priceOptions.push(Math.round((listing.price * 0.985) / 100) * 100);
  priceOptions.push(Math.round((listing.price * 0.97) / 100) * 100);
  priceOptions.push(Math.round((listing.price * 0.955) / 100) * 100);
  return priceOptions;
};

const AdjustPriceModal = NiceModal.create(
  ({ listing }: AdjustPriceModalProps) => {
    const modal = useModal();
    const dispatch = useAppDispatch();
    const priceOptions = createPriceOptions(listing);
    const [selectedPrice, setSelectedPrice] = useState<number>(priceOptions[0]);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleAdjustPrice = async () => {
      await listingsService.patch({
        id: listing.id,
        data: { price: selectedPrice },
      });
      dispatch(fetchListings({}));
      modal.remove();
    };

    return (
      <Dialog open={true} onClose={() => modal.remove()}>
        <DialogTitle>Adjust Price</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedPrice}
            onChange={(e) => setSelectedPrice(Number(e.target.value))}
          >
            {priceOptions.map((price) => {
              const percentageOff = Number(
                (((listing.price - price) / listing.price) * 100).toFixed(1)
              );
              return (
                <FormControlLabel
                  key={price}
                  value={price}
                  control={<Radio />}
                  label={`${formatCurrency(price)} (${percentageOff}% off)`}
                />
              );
            })}
          </RadioGroup>
          <MuiFormControlLabel
            control={
              <Checkbox
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
              />
            }
            label="I confirm this price change"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => modal.remove()} color="error">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAdjustPrice}
            disabled={!isConfirmed}
          >
            Update Price
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default AdjustPriceModal;
