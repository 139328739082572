/* eslint-disable max-len */
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

const Featured: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack direction="column" alignItems={'center'} spacing={1} p={1}>
      <Typography variant="h6" fontWeight={500}>
        Featured in
      </Typography>
      <Stack
        direction="row"
        spacing={isMobile ? 0 : 3}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        <a
          // eslint-disable-next-line max-len
          href="https://bhamnow.com/2024/09/06/birmingham-couple-new-way-to-real-estate/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://i0.wp.com/bhamnow.com/wp-content/uploads/2016/07/Header-Plain-e1470599322158.png?fit=1200%2C280&quality=80&ssl=1"
            width="200"
          />
        </a>
        <a
          href="https://www.wicz.com/story/51130875/homepoint-gives-homeowners-more-profits-from-each-real-estate-transaction"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://wicz.images.worldnow.com/images/18109670_G.png?auto=webp&disable=upscale&dpr=2&height=70&fit=bounds"
            width="200"
          />
        </a>
      </Stack>
    </Stack>
  );
};

export default Featured;
