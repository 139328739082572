import { Prisma, users } from '@prisma/client';
import axiosInstance from '../api';

interface UsersCreate extends Prisma.usersCreateInput {
  listingId?: string;
}

const usersService = {
  find: async ({ email }: { email?: string }): Promise<users[]> => {
    const response = await axiosInstance.get('/users', { params: { email } });
    return response.data;
  },
  post: async (user: UsersCreate) => {
    const res = await axiosInstance.post('/users', user);
    return res;
  },
  patch: async (user: Prisma.usersUpdateInput, userId: string) => {
    const res = await axiosInstance.patch(`/users/${userId}`, user);
    return res.data;
  },
  remove: async (userId: string) => {
    const res = await axiosInstance.delete(`/users/${userId}`);
    return res.data;
  },
};

export default usersService;
