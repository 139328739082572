import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  Grid,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useAppDispatch } from '../store/store';
import { login as auth } from '../store/slices/auth';
import { users } from '@prisma/client';
import LoadingButton from '@mui/lab/LoadingButton';
import forgotPasswordService from '../api/services/forgotPassword';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState<string | null>(
    searchParams.get('message')
  );
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const location = useLocation();
  const from = location.state?.from?.pathname;

  const handleForgotPassword = async (email: string) => {
    await forgotPasswordService.patch({ email });
    searchParams.set(
      'message',
      'You may receive an email with a forgot password link.'
    );
    setForgotPasswordOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      setError(false);

      try {
        const res = await dispatch(
          auth({ email: values.email, password: values.password })
        );
        const user = res.payload as users;
        if (user.id) {
          if (!user.verified) {
            setMessage(
              // eslint-disable-next-line max-len
              'Your email has not been verified. Please check your email for your verification email. If you cannot find it, you can reset your password to receive a new email.'
            );
            setLoading(false);
            setError(false);
            return;
          }
          if (from) {
            navigate(from, { replace: true });
          } else if (user.type === 'PBUYER') {
            navigate(`/buyers/${user.id}`);
          } else if (user.type === 'BUYER') {
            navigate('/buying');
          } else {
            navigate('/selling');
          }
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Grid container spacing={0}>
        <Grid
          container
          item
          xs={12}
          sm={6}
          direction="column"
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Box sx={{ padding: 4, width: '100%', maxWidth: 500 }}>
            <Stack direction={'column'} spacing={2}>
              <Link to="/">
                <img src="/new_logo_light.png" width="100%" />
              </Link>
              <Typography variant="h4" sx={{ fontWeight: 700 }} fontSize={32}>
                Welcome Back!
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Please enter your details.
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  required
                  sx={{ mb: 2 }}
                  placeholder="hi@example.com"
                  {...formik.getFieldProps('email')}
                />
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  placeholder="Enter password"
                  {...formik.getFieldProps('password')}
                />
                {error && (
                  <Alert severity="error" sx={{ mt: 1, mb: 1 }}>
                    Unable to log in. Please double-check your email and
                    password and try again.
                    <br />
                    <br />
                    If you did not complete your account setup, you may reset
                    your password to receive a new email.
                  </Alert>
                )}
                {message && (
                  <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
                    {decodeURIComponent(message)}
                  </Alert>
                )}
                <Button
                  variant="text"
                  fullWidth
                  color="secondary"
                  sx={{ mb: 1 }}
                  onClick={() => setForgotPasswordOpen(true)}
                >
                  Forgot Password?
                </Button>
                <LoadingButton
                  variant="contained"
                  sx={{ mb: 2, backgroundColor: 'primary' }}
                  fullWidth
                  type="submit"
                  loading={loading}
                >
                  Log In
                </LoadingButton>
              </form>
              <Typography variant="subtitle2" align="center">
                Don’t have an account?{' '}
                <Link color="secondary" to="/signup">
                  Sign up for free
                </Link>
              </Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid
          container
          item
          sm={6}
          direction="column"
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        >
          {' '}
          <Box
            minHeight="100vh"
            sx={{
              backgroundImage: 'url("/houses/house4_small.png")',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        open={forgotPasswordOpen}
        onClose={() => {
          setForgotPasswordOpen(false);
        }}
      >
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <Typography mb={2}>
            Enter your email below to receive a reset password link.
          </Typography>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            type="email"
            required
            placeholder="hi@example.com"
            autoFocus
            onChange={(e) => formik.setFieldValue('email', e.target.value)}
            sx={{ mt: 0.5, minWidth: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setForgotPasswordOpen(false)}>Cancel</Button>
          <Button onClick={() => handleForgotPassword(formik.values.email)}>
            Send Reset Email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Login;
