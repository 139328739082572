import { Chip, Stack, Typography } from '@mui/material';
import { ExternalStatus as ES } from '@prisma/client';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { lighten, PaletteColor } from '@mui/material/styles';
import { useState } from 'react';

interface Props {
  status: ES | null;
  liveDate?: Date | null;
  hideTooltip?: boolean;
}

const getTextForStatus = (status: ES | null) => {
  switch (status) {
    case 'ACTIVE':
      return 'Active';
    case 'SOLD':
      return 'Sold';
    case 'COMING_SOON':
      return 'Coming Soon';
    case 'PENDING':
      return 'Pending Sale';
    default:
      return '';
  }
};

const getTooltipTextForStatus = (status: ES | null) => {
  switch (status) {
    case 'ACTIVE':
      // eslint-disable-next-line max-len
      return 'The property is active on Homepoint, the MLS, and visible to the public.';
    case 'SOLD':
      return 'The property has been sold.';
    case 'COMING_SOON':
      // eslint-disable-next-line max-len
      return 'The property is shown on Homepoint, but not yet active for tours, sale, or on the market.';
    case 'PENDING':
      return 'The property is pending sale.';
  }
};

const getColorForStatus = (status: ES | null) => {
  switch (status) {
    case 'ACTIVE':
      return 'primary';
    case 'SOLD':
      return 'error';
    case 'COMING_SOON':
      return 'warning';
    case 'PENDING':
      return 'info';
  }
};

const ExternalStatus: React.FC<Props> = ({ status, liveDate, hideTooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  if (!status) return null;

  const StatusChip = (
    <Chip
      label={
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption" fontWeight={600}>
            {getTextForStatus(status)}
            {liveDate &&
              status === 'COMING_SOON' &&
              `, Active ${liveDate.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
              })}`}
          </Typography>
        </Stack>
      }
      clickable={false}
      onClick={() => setShowTooltip(!showTooltip)}
      sx={{
        width: 'fit-content',
        backgroundColor: (theme) => {
          const color = getColorForStatus(status);
          return lighten(
            (theme.palette[color as keyof typeof theme.palette] as PaletteColor)
              .main,
            0.4
          );
        },
      }}
    />
  );

  if (hideTooltip) return StatusChip;

  return (
    <CustomTooltip
      title={
        <Typography variant="body2">
          {getTooltipTextForStatus(status)}
        </Typography>
      }
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      {StatusChip}
    </CustomTooltip>
  );
};

export default ExternalStatus;
