import { last } from 'lodash';
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Define the context type
interface BackButtonContextType {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  goBack: () => void;
}

// Create context with default values
const BackButtonContext = createContext<BackButtonContextType | undefined>(
  undefined
);

// Create provider component props type
interface BackButtonProviderProps {
  children: ReactNode;
}

// Create provider component
export const BackButtonProvider: React.FC<BackButtonProviderProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    setHistory((prevHistory) => [...prevHistory, location.pathname]);
  }, [location.pathname]);

  useEffect(() => {
    // Check if there is history to go back to
    if (history[history.length - 2] === '/login') {
      setIsVisible(false);
    } else if (history.length > 1) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [location]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <BackButtonContext.Provider value={{ isVisible, setIsVisible, goBack }}>
      {children}
    </BackButtonContext.Provider>
  );
};

// Custom hook to use the BackButtonContext
export const useBackButton = (): BackButtonContextType => {
  const context = useContext(BackButtonContext);
  if (context === undefined) {
    throw new Error('useBackButton must be used within a BackButtonProvider');
  }
  return context;
};
