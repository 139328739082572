import axiosInstance from '../api';
import { comps } from '@prisma/client';

export interface compsWithEstPrice {
  comps: comps[];
  estPrice: {
    low: number;
    price: number;
    high: number;
  };
}
const compsService = {
  find: async (listingId: string): Promise<compsWithEstPrice> => {
    const response = await axiosInstance.get(
      `/comps?listingId=${listingId}`,
      {}
    );
    return response.data;
  },
  get: async (id: string): Promise<comps> => {
    const response = await axiosInstance.get(`/comps/${id}`, {});
    return response.data;
  },
  create: async (data: { refId: string; listingId: string }) => {
    const response = await axiosInstance.post('/comps', data);
    return response.data;
  },
  remove: async (id: string) => {
    const response = await axiosInstance.delete(`/comps/${id}`);
    return response.data;
  },
};

export default compsService;
