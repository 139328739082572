import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Stack,
  Button,
  styled,
  tableRowClasses,
} from '@mui/material';
import { useAppDispatch, RootState } from '../../store/store';
import { selectActiveSellingOffers } from '../../store/selectors/offers';
import { fetchOffers } from '../../store/slices/offers';
import { offers } from '@prisma/client';
import { formatCurrency } from '../../utils/formatCurrency';
import { capitalize } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';
import { selectMySellingListings } from '../../store/selectors/listings';
import { InfoOutlined } from '@mui/icons-material';
import { CustomTooltip } from '../../components/CustomTooltip/CustomTooltip';
import NiceModal from '@ebay/nice-modal-react';
// eslint-disable-next-line max-len
import OfferConfirmModal, {
  OFFER_CONFIRM_MODAL_TYPE,
} from '../../components/OfferConfirmationModal/OfferConfirmModal';
import offersService from '../../api/services/offers';
import Proceeds from './Proceeds';
import { fetchListings } from '../../store/slices/listings';

const calculateOfferScore = (offer: offers, homePrice: number) => {
  // Weights
  const WEIGHT_CASH_OFFER = 0.25;
  const WEIGHT_OFFER_PRICE = 0.2;
  const WEIGHT_CLOSING_DATE = 0.1;
  const WEIGHT_INSPECTION_PERIOD = 0.1;
  const WEIGHT_CONTINGENT = 0.25;
  const WEIGHT_EARNEST_MONEY = 0.1;
  // Cash Offer Score
  const cashOfferScore = offer.paymentMethod.toLowerCase() === 'cash' ? 1 : 0;
  // Offer Price Score
  const offerPriceScore = Math.min(offer.price / homePrice, 1); // Normalized between 0 and 1
  // Closing Date Score
  const daysUntilClosing = differenceInCalendarDays(
    new Date(offer.closingDate),
    new Date()
  );
  const closingDateScore = Math.max(1 - daysUntilClosing / 365, 0); // Normalize to 0 - 1, favoring sooner dates
  // Inspection Period Score
  const inspectionPeriodScore = offer.inspectionPeriod
    ? Math.max(1 - offer.inspectionPeriod / 30, 0)
    : 1; // Favor shorter periods, normalize to 0 - 1
  // Contingent Score
  const contingentScore = offer.contingent ? 0 : 1; // Favor non-contingent offers
  // Earnest Money Score
  const earnestMoneyScore = Math.min(offer.earnestMoney / homePrice, 1); // Normalized between 0 and 1

  const netProceeds = offer.price - offer.price * offer.bagentComm;
  const netProceedsScore = Math.min(netProceeds / homePrice, 1); // Normalize between 0 and 1

  // Composite Score
  const compositeScore =
    WEIGHT_CASH_OFFER * cashOfferScore +
    WEIGHT_OFFER_PRICE * offerPriceScore +
    WEIGHT_CLOSING_DATE * closingDateScore +
    WEIGHT_INSPECTION_PERIOD * inspectionPeriodScore +
    WEIGHT_CONTINGENT * contingentScore +
    WEIGHT_EARNEST_MONEY * earnestMoneyScore +
    WEIGHT_OFFER_PRICE * netProceedsScore; // Incorporating the impact of commission

  return compositeScore;
};

const rankOffers = (offers: offers[], homePrice: number) => {
  // Calculate base scores
  const scoredOffers = offers.map((offer) => ({
    ...offer,
    proceeds: offer.price * ((100 - offer.bagentComm) / 100) - 2500,
    baseScore: calculateOfferScore(offer, homePrice),
  }));

  // Sort offers by baseScore in descending order
  scoredOffers.sort((a, b) => b.baseScore - a.baseScore);

  // Assign relative scores
  return scoredOffers.map((offer, index) => {
    let relativeScore = scoredOffers.length - index - 1;
    if (offers.length === 1) {
      relativeScore = 1;
    }
    return { ...offer, relativeScore };
  });
};

const Offers: React.FC = () => {
  const dispatch = useAppDispatch();
  const offers = useSelector((state: RootState) =>
    selectActiveSellingOffers(state)
  );
  const myListings = useSelector((state: RootState) =>
    selectMySellingListings(state)
  );
  const myListing = myListings[0];
  const rankedOffers = rankOffers(offers, myListings[0]?.price ?? 0);

  const dispatchOffers = () => {
    dispatch(fetchOffers());
    dispatch(fetchListings({}));
  };

  useEffect(() => {
    dispatchOffers();
  }, [dispatch]);

  const columns = [
    'Buyer',
    'Price',
    'Payment Method',
    'Inspection',
    'Closing Date',
    'Earnest Money',
    'Contingent',
    "Buyer's Commissions",
    'Additional Terms',
    'Estimated Proceeds',
    'Score',
    '',
  ];

  const onOfferAccepted = async (offer: offers) => {
    const notAcceptedOffers = offers.filter((of: offers) => of.id !== offer.id);
    notAcceptedOffers.map((noOffer: offers) => {
      offersService.patch(noOffer.id, { status: 'DECLINED' });
    });
    dispatchOffers();
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    [`&.${tableRowClasses.root}`]: {
      height: '20px',
      padding: 0,
    },
  }));

  const transposedData = columns.map((col, index) => {
    return {
      columnName: col,
      data: rankedOffers.map((offer: any) => {
        switch (index) {
          case 0:
            return `${offer.user.firstName} ${offer.user.lastName}`;
          case 1:
            return formatCurrency(offer.price);
          case 2:
            return capitalize(offer.paymentMethod);
          case 3:
            return offer.inspectionPeriod ? (
              <Typography variant="body2" component={'span'}>
                Yes,{' '}
                <Typography variant="body2" component={'span'} fontWeight={500}>
                  {offer.inspectionPeriod} days
                </Typography>
              </Typography>
            ) : (
              'No'
            );
          case 4:
            return (
              <Typography variant="body2" component={'span'}>
                {new Date(offer.closingDate).toLocaleDateString()},&nbsp;
                <Typography variant="body2" component={'span'} fontWeight={500}>
                  {differenceInCalendarDays(
                    new Date(offer.closingDate),
                    new Date()
                  )}{' '}
                  days
                </Typography>
              </Typography>
            );
          case 5:
            return formatCurrency(offer.earnestMoney);
          case 6:
            return offer.contingent ? 'Yes' : 'No';
          case 7:
            return `${offer.bagentComm}%`;
          case 8:
            return offer.additionalTerms === ''
              ? 'None'
              : offer.additionalTerms;
          case 9:
            return (
              <Proceeds listingId={myListing?.id ?? ''} offerId={offer.id} />
            );
          case 10:
            if (offer.relativeScore > 0) {
              return (
                <Typography
                  variant="body1"
                  color="success.main"
                  fontWeight={700}
                >
                  +{offer.relativeScore}
                </Typography>
              );
            } else {
              return (
                <Typography variant="body2">{offer.relativeScore}</Typography>
              );
            }
          case 11: {
            const status = offer.status;
            const pending = status === 'PENDING';
            let textColor;

            if (offer.status === 'ACCEPTED') {
              textColor = 'success.main';
            } else if (offer.status === 'DECLINED') {
              textColor = 'error.main';
            } else {
              textColor = '#000';
            }

            if (pending) {
              return (
                <Stack direction="row" spacing={1}>
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => {
                      NiceModal.show(OfferConfirmModal, {
                        handleOnSubmit: onOfferAccepted,
                        offer,
                        type: OFFER_CONFIRM_MODAL_TYPE.ACCEPT,
                      });
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      NiceModal.show(OfferConfirmModal, {
                        handleOnSubmit: () => dispatchOffers(),
                        offer,
                        type: OFFER_CONFIRM_MODAL_TYPE.COUNTER,
                      });
                    }}
                  >
                    Counter
                  </Button>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      NiceModal.show(OfferConfirmModal, {
                        handleOnSubmit: () => {
                          dispatchOffers();
                        },
                        offer,
                        type: OFFER_CONFIRM_MODAL_TYPE.DECLINE,
                      });
                    }}
                  >
                    Decline
                  </Button>
                </Stack>
              );
            }
            return (
              <Typography color={textColor}>
                {capitalize(offer.status)}
              </Typography>
            );
          }
          default:
            return '';
        }
      }),
    };
  });

  const renderColName = (colName: string) => {
    if (colName === 'Score') {
      return (
        <CustomTooltip
          title={
            <Box p={1}>
              <Typography variant="body2">
                We calculate a score for offers based on these critieria.
                <ul>
                  <li>Cash vs Financing</li>
                  <li>Soonest Closing Date</li>
                  <li>Offer Price</li>
                  <li>Inspection Required</li>
                </ul>
              </Typography>
            </Box>
          }
        >
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={500}>
              Score
            </Typography>
            <InfoOutlined fontSize="small" />
          </Stack>
        </CustomTooltip>
      );
    }
    return colName;
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
      >
        <Typography variant="h6" fontWeight={500}>
          Offers
        </Typography>
        {/* <Button variant="contained">Call for highest offers</Button> */}
      </Stack>
      <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {rankedOffers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={12}>
                    <Typography textAlign={'center'} p={2}>
                      You currently have no offers
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {/* <TableRow>
                <TableCell></TableCell>
                {rankedOffers.map((_, index) => (
                  <TableCell key={index} sx={{ fontWeight: 600 }}>
                    Offer {index + 1}
                  </TableCell>
                ))}
              </TableRow> */}
            </TableHead>
            <TableBody>
              {rankedOffers.length > 0 &&
                transposedData.map((row, rowIndex) => (
                  <StyledTableRow key={rowIndex}>
                    <TableCell sx={{ fontWeight: 600 }}>
                      {renderColName(row.columnName)}
                    </TableCell>
                    {row.data.map((cell, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          borderLeft: '1px solid #eee',
                        }}
                      >
                        {cell}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Stack>
  );
};

export default Offers;
