import { Home } from '@mui/icons-material';
import {
  Typography,
  Stack,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import FormSVG from '/form_input.svg';
import ForSale from '/for_sale.svg';
import Review from '/review.svg';
import ClosingSVG from '/closing.svg';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const HIWSelling: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Typography
        variant="h4"
        textAlign={'center'}
        sx={{ fontWeight: 700, mt: 4 }}
      >
        How Selling Works
      </Typography>
      {/* <Typography variant="subtitle2" textAlign={'center'}>
        The experience of a traditional agent for a flat fee.
      </Typography> */}
      <Typography variant="subtitle1" textAlign={'center'}>
        Get support from a real estate agent at any time.
      </Typography>
      <Grid2 container>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 1</Typography>
            <Typography variant="h5" fontWeight={600}>
              List Your Home
            </Typography>
            Sign up and start your listing on Homepoint.
          </Stack>
          <Typography>
            <ul>
              <li>Step-by-step guide to creating the perfect home listing</li>
              <li>
                Curated listing descriptions from your home&apos;s details
              </li>
              <li>We provide professional photos and floorplan</li>
              <li>Let an agent price your home or set a price yourself</li>
              <li>MLS listing to market your home</li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={FormSVG} height={350} />
            </Grid2>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={ForSale} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 2</Typography>
            <Typography variant="h5" fontWeight={600}>
              We Market Your Home
            </Typography>
            Your home is marketed just like a traditional home sale.
          </Stack>
          <Typography>
            <ul>
              <li>MLS listing for your home</li>
              <li>For sale sign</li>
              <li>Social media marketing</li>
              <li>Open houses</li>
              <li>
                Manage buyer tours and receive tour feedback from anywhere
              </li>
            </ul>
          </Typography>
        </Grid2>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 3</Typography>
            <Typography variant="h5" fontWeight={600}>
              Pick Your Best Offer
            </Typography>
            Compare all your offers on Homepoint and choose the best one.
          </Stack>
          <Typography>
            <ul>
              <li>View potential buyers directly in your dashboard</li>
              <li>Compare offers easily on Homepoint</li>
              <li>Manage negotiations effortlessly on Homepoint</li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={mobile ? 0 : 6} textAlign={'center'}>
              <img src={Review} height={350} />
            </Grid2>
            <Grid2 xs={mobile ? 0 : 6} textAlign={'center'}>
              <img src={ClosingSVG} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="subtitle2">Step 4</Typography>
            <Typography variant="h5" fontWeight={600}>
              Close and Celebrate!
            </Typography>
            <ul>
              <li>
                We generate all the necessary documents to ensure the
                transaction is fully prepared.
              </li>
              <li>
                The closing is handled like a traditional closing with title
                companies and attorneys.
              </li>
              <li>
                We make sure everything is ready so your closing goes smoothly
                and you can celebrate your new home.
              </li>
            </ul>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

export default HIWSelling;
