import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

const offersService = {
  find: async () => {
    const res = await axiosInstance.get('/offers');
    return res.data;
  },
  post: async (
    data: Prisma.offersCreateWithoutUserInput & { sessionId: string },
    buyer = false
  ) => {
    const res = await axiosInstance.post('/offers', data, {
      params: { buyer },
    });
    return res.data;
  },
  patch: async (id: string, data: Prisma.offersUpdateInput) => {
    const res = await axiosInstance.patch(`/offers/${id}`, { ...data });
    return res.data;
  },
};

export default offersService;
