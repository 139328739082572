import { useFormik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Alert,
  Stack,
} from '@mui/material';
import zxcvbn from 'zxcvbn';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Warning } from '@mui/icons-material';
import forgotPasswordService from '../api/services/forgotPassword';
import { useEffect, useState } from 'react';
import { capitalize } from 'lodash';

const Password = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const type = params.get('type') ?? 'reset';
  const text = capitalize(type);
  const token = params.get('token') ?? '';

  const [strength, setStrength] = useState<ReturnType<typeof zxcvbn> | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      const res = await forgotPasswordService.post({
        token,
        newPassword: values.password,
      });
      if (res.id) {
        navigate(
          `/login?message=Password ${type} successfully. You may now log in.`
        );
      } else {
        setError('Error resetting password');
      }
    },
  });

  useEffect(() => {
    setStrength(zxcvbn(formik.values.password));
  }, [formik.values.password]);

  return (
    <>
      <Grid container spacing={0}>
        <Grid
          container
          item
          xs={12}
          sm={6}
          direction="column"
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Box sx={{ padding: 4, width: '100%', maxWidth: 500 }}>
            <form onSubmit={formik.handleSubmit}>
              <Stack direction={'column'} spacing={2}>
                <Link to="/">
                  <img src="/new_logo_light.png" width="100%" />
                </Link>
                <Typography variant="h4" sx={{ fontWeight: 700 }} fontSize={32}>
                  {text} Password
                </Typography>
                <TextField
                  label="Password"
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  {...formik.getFieldProps('password')}
                  sx={{ mt: 2 }}
                />
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  fullWidth
                  required
                  type="password"
                  {...formik.getFieldProps('confirmPassword')}
                  sx={{ mt: 2 }}
                />
                {error && (
                  <Alert icon={<Warning />} color="error" sx={{ mt: 2, mb: 1 }}>
                    {error}
                  </Alert>
                )}
                {strength && (
                  <div>
                    {strength.feedback.suggestions.length > 0 && (
                      <ul style={{ fontSize: 16 }}>
                        {strength.feedback.suggestions.map(
                          (suggestion, index) => (
                            <li key={index}>{suggestion}</li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: 'primary', mt: 2 }}
                  fullWidth
                >
                  {text} Password
                </Button>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid
          container
          item
          sm={6}
          direction="column"
          sx={{
            display: {
              xs: 'none',
              sm: 'flex',
            },
          }}
        >
          {' '}
          <Box
            minHeight="100vh"
            sx={{
              backgroundImage: 'url("/houses/house4_small.png")',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Password;
