import axios from 'axios';
import axiosInstance from '../api';
import {
  Prisma,
  comps,
  listings,
  pricingOpinions,
  uploads,
} from '@prisma/client';

export interface listingsWithUploads extends listings {
  uploads: uploads[];
}

export interface listingsWithExtra extends listingsWithUploads {
  comps: comps[];
  estimatedPrice: number;
}

export interface listingsWithPricingOpinion extends listingsWithExtra {
  pricingOpinion: pricingOpinions;
}

const listingsService = {
  find: async ({
    params,
  }: {
    params?: Prisma.listingsFindManyArgs;
  }): Promise<listingsWithUploads[]> => {
    const response = await axiosInstance.get('/listings', { params });
    return response.data;
  },
  get: async ({
    id,
    params,
  }: {
    id: string;
    params?: any;
  }): Promise<listingsWithExtra> => {
    const response = await axiosInstance.get(`/listings/${id}`, { params });
    return response.data;
  },
  patch: async ({
    id,
    data,
  }: {
    id: string;
    data: Partial<listings>;
  }): Promise<listings> => {
    const response = await axiosInstance.patch(`/listings/${id}`, data);
    return response.data;
  },
  create: async (refId: string, buyer = false) => {
    const response = await axiosInstance.post(
      '/listings',
      { refId },
      { params: { buyer } }
    );
    return response.data;
  },
};

export default listingsService;
