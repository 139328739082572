import { Grid, Typography } from '@mui/material';

const NAR = () => {
  return (
    <>
      <Grid container gap={2} direction={'column'}>
        <Grid xs={12} textAlign={'center'}>
          <Typography variant="h4" fontWeight={600}>
            NAR Settlement
          </Typography>
          <Typography variant="h6" fontWeight={600}>
            The Effects of the Recent NAR Settlement on Home Buying
          </Typography>
        </Grid>
        <Grid xs={12} textAlign={'left'}>
          <Typography>
            The recent settlement between the National Association of Realtors
            (NAR) and the Department of Justice (DOJ) has sent ripples through
            the real estate industry, with significant implications for home
            buyers, sellers, and real estate professionals. This landmark
            decision aims to foster greater transparency, competition, and
            fairness in the housing market, ultimately benefiting consumers in
            several ways. Here’s a closer look at the key aspects of the
            settlement and how it impacts the home buying process.
            <br />
            <br />
            <b>Increased Transparency in Real Estate Transactions</b>
            <br />
            One of the primary outcomes of the NAR settlement is the increased
            transparency in real estate transactions. Historically, the real
            estate industry has been criticized for its opaque pricing
            structures and hidden fees. Buyers often entered the market without
            a clear understanding of the costs involved or the services provided
            by real estate agents. The settlement mandates that real estate
            agents disclose all fees and commissions upfront, allowing buyers to
            make more informed decisions. This transparency helps buyers
            understand exactly what they are paying for and compare the services
            offered by different agents, leading to more competitive pricing and
            better service quality.
            <br />
            <br />
            <b>Enhanced Competition Among Real Estate Agents</b>
            <br />
            The settlement also encourages greater competition among real estate
            agents. By requiring agents to disclose their commissions, the
            settlement breaks down barriers that previously prevented buyers
            from comparing agents based on cost and service quality. As a
            result, agents are now incentivized to offer more competitive rates
            and better services to attract clients. This increased competition
            benefits buyers by providing them with a wider range of options and
            potentially lower costs for real estate services. Buyers can now
            choose agents based on their expertise, track record, and the value
            they provide, rather than being limited to a select few options.
            <br />
            <br />
            <b>Lower Costs for Home Buyers</b>
            <br />
            One of the most direct effects of the settlement is the potential
            for lower costs for home buyers. With increased transparency and
            competition, buyers are likely to see a reduction in the fees and
            commissions they pay to real estate agents. The settlement&apos;s
            emphasis on upfront disclosure of costs allows buyers to negotiate
            better deals and avoid unexpected expenses. This reduction in
            transaction costs can make homeownership more affordable,
            particularly for first-time buyers who often face financial
            challenges in entering the housing market. Lower costs also mean
            that buyers have more funds available for other expenses, such as
            home improvements or moving costs.
            <br />
            <br />
            <b>Improved Consumer Confidence</b>
            <br />
            The NAR settlement is expected to boost consumer confidence in the
            real estate market. By addressing long-standing concerns about
            transparency and fairness, the settlement helps rebuild trust
            between consumers and real estate professionals. Buyers can now
            approach the home buying process with greater confidence, knowing
            that they have access to clear information and competitive options.
            This improved confidence can lead to a more active and dynamic
            housing market, as more buyers feel empowered to make informed
            decisions and pursue their homeownership goals.
            <br />
            <br />
            <b>Challenges and Considerations</b>
            <br />
            While the NAR settlement brings many positive changes, it also
            presents some challenges and considerations for the real estate
            industry. Real estate agents may need to adapt to the new
            requirements and find ways to differentiate themselves in a more
            competitive market. Additionally, the increased focus on
            transparency and competition may lead to a reevaluation of
            traditional business models and practices within the industry. Real
            estate firms may need to invest in new technologies and training to
            ensure compliance with the settlement’s provisions and to meet the
            evolving expectations of consumers.
            <br />
            <br />
            <b>Conclusion</b>
            <br />
            The recent NAR settlement marks a significant step towards a more
            transparent, competitive, and consumer-friendly real estate market.
            By increasing transparency, enhancing competition, and potentially
            lowering costs, the settlement offers substantial benefits for home
            buyers. While the industry may face some challenges in adapting to
            the new requirements, the overall impact is expected to be positive,
            fostering greater trust and confidence in the home buying process.
            For buyers, this settlement represents a welcome change that
            empowers them to make more informed decisions and achieve their
            homeownership dreams with greater ease and assurance.
            <br />
            <br />
            <b>Homepoint and the Settlement</b>
            <br />
            Homepoint is an excellent solution for home sellers and buyers,
            especially in light of the recent National Association of Realtors
            (NAR) settlement. The settlement, which addresses anti-competitive
            practices and commission structures, highlights the need for more
            transparency and cost-effective alternatives in the real estate
            market.
            <br />
            <br />
            Here&apos;s why Homepoint stands out:
            <ul>
              <li>
                <b>Flat Fee Structure</b>: Unlike traditional agents who charge
                a percentage-based commission, Homepoint offers a flat fee
                model. This ensures that buyers and sellers know exactly what
                they are paying upfront, providing greater financial
                predictability and often resulting in significant cost savings.
              </li>
              <li>
                <b>Flexibility and Control</b>: Homepoint empowers users to
                manage much of the buying and selling process themselves through
                its software platform. This self-service model aligns well with
                the increased demand for transparency and control that the NAR
                settlement aims to promote.
              </li>
              <li>
                <b>Professional Support On-Demand</b>: While Homepoint enables
                users to handle many aspects of the transaction independently,
                it also offers the option to consult with real estate
                professionals whenever needed. This hybrid approach combines the
                best of both worlds: autonomy and expert guidance.
              </li>
            </ul>
            By addressing the core issues of cost transparency and providing
            flexible, user-driven solutions, Homepoint is well-positioned to
            meet the evolving needs of the real estate market post-settlement.
          </Typography>
        </Grid>
      </Grid>
      <br />
      <br />
    </>
  );
};

export default NAR;
