import { Stack, TextField, Button, useTheme, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import newslettersService from '../../api/services/newsletters';
import { useState } from 'react';
import { H } from 'highlight.run';

interface Props {
  mobile: any;
  title?: string;
  color?: any;
}

const WaitlistForm: React.FC<Props> = ({ mobile, title, color }) => {
  const theme = useTheme();
  const [success, setSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address.')
        .required('Email required.'),
    }),
    onSubmit: async (values) => {
      await newslettersService.post({ email: values.email });
      setSuccess(true);
      H.track('Newsletter Signup');
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ width: mobile ? '100%' : undefined }}
    >
      <Stack
        direction={mobile ? 'column' : 'row'}
        alignItems={'center'}
        mt={2}
        spacing={2}
      >
        <TextField
          name="email"
          placeholder="Enter your email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          size="small"
          fullWidth
          sx={{
            '& .MuiInputBase-input': {
              backgroundColor: 'white',
              borderRadius: 1,
              border: '1px solid #ccc',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: theme.palette.secondary.main,
              },
            },
          }}
          required
        />
        <Button
          variant="contained"
          fullWidth={mobile}
          color={color ? color : 'highlight'}
          sx={{
            minWidth: mobile ? 'auto' : 200, // Ensures button has auto width on mobile, 300px otherwise
            whiteSpace: 'nowrap',
            width: mobile ? '100%' : 300, // Ensures full width on mobile, 300px otherwise
            fontWeight: 700,
          }}
          type="submit"
        >
          {title ? title : 'Sign up for free'}
        </Button>
      </Stack>
      {success && (
        <Typography textAlign={'center'} pt={1}>
          Thank you! You are signed up.
        </Typography>
      )}
    </form>
  );
};

export default WaitlistForm;
