import { TooltipProps, Tooltip, styled } from '@mui/material';
import tooltipClasses from '@mui/material/Tooltip/tooltipClasses';
interface CustomTooltipProps extends TooltipProps {
  width?: string;
}

export const CustomTooltip = styled(
  ({ className, width, ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)<{ width?: string }>(({ theme, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    width: width || 'auto', // Set width dynamically based on the prop
    maxWidth: width ? 'none' : undefined, // Adjust maxWidth if width is provided
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));
