// rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
import listingsReducer from './slices/listings';
import authReducer from './slices/auth';
import showingsReducer from './slices/showings';
import messagesReducer from './slices/messages';
import notificationsReducer from './slices/notifications';
import offersReducer from './slices/offers';

const rootReducer = combineReducers({
  listings: listingsReducer,
  auth: authReducer,
  showings: showingsReducer,
  messages: messagesReducer,
  offers: offersReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
