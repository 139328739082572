import { CheckBoxRounded, Check } from '@mui/icons-material';
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Slider,
} from '@mui/material';
import { formatCurrency } from '../../../utils/formatCurrency';
import { useState } from 'react';
import HowItWorksV2 from './HIWSelling';

interface Props {
  mobile: boolean;
  price: number;
  handlePriceChange: (price: number) => void;
}

const Seller: React.FC<Props> = ({ mobile, price, handlePriceChange }) => {
  const traditionalFee = price * 0.06;
  const homepointFee = 2500;
  const savings = traditionalFee - price * 0.03 - 2500;

  return (
    <>
      <Typography
        variant="h4"
        textAlign={'center'}
        sx={{ fontWeight: 700 }}
        pt={mobile ? 2 : 0}
      >
        The Homepoint Difference
      </Typography>
      <Stack direction="column" alignItems={'center'} pt={2}>
        <Typography variant="subtitle2">
          Savings based on a <b>${price / 1000}k</b> home sale
        </Typography>
        <Slider
          min={100000}
          max={1000000}
          value={price}
          step={50000}
          onChange={(event: any) =>
            handlePriceChange(
              event.target?.value === '' ? 0 : Number(event.target?.value)
            )
          }
          sx={{ width: '100%', maxWidth: 500 }}
        />
      </Stack>
      <Box sx={{ width: '100%', pt: 3 }}>
        <TableContainer
          sx={{
            borderRadius: 2,
            border: '1px solid #ccc',
          }}
        >
          <Table
            sx={{
              borderCollapse: 'collapse',
              borderStyle: 'hidden',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Homepoint
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">Traditional Agent</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <b>Savings</b>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    color="success.main"
                    fontWeight={700}
                  >
                    {formatCurrency(savings)}
                  </Typography>
                  <Typography variant="caption">
                    Includes 3% Buyer&apos;s Agent Commission
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={700}>
                    {formatCurrency(0)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>
                    MLS Listing
                    <br />
                    and Marketing
                  </b>
                </TableCell>
                <TableCell>
                  <CheckBoxRounded color="primary" />
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <b>Electronic Lockbox</b>
                </TableCell>
                <TableCell>
                  <CheckBoxRounded color="primary" />
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Professional Photos</b>
                </TableCell>
                <TableCell>
                  <Stack direction={mobile ? 'column' : 'row'}>
                    <CheckBoxRounded color="primary" />
                    <Typography>
                      <b> + Floorplan</b>
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: 'white' }}>
                <TableCell>
                  <b>Support</b>
                  <br />
                  <Typography variant="caption">At every step</Typography>
                </TableCell>
                <TableCell>
                  <Stack direction={mobile ? 'column' : 'row'}>
                    <CheckBoxRounded color="primary" />
                  </Stack>
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Cost</b>
                  <br />
                  <Typography variant="caption">Paid at closing</Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    color="success.main"
                    fontWeight={700}
                  >
                    {formatCurrency(homepointFee)}
                  </Typography>
                  <Typography variant="caption">Homepoint Fee</Typography>
                </TableCell>
                <TableCell>
                  {formatCurrency(traditionalFee)}
                  <br />
                  <Typography variant="caption">
                    3% Seller&apos;s + 3% Buyer&apos;s Agent Commission
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div id="hiw" />
      <HowItWorksV2 />
    </>
  );
};

export default Seller;
