import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  DialogTitle,
  DialogContent,
  TextField,
  Dialog,
  Button,
  InputAdornment,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { listings } from '@prisma/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { formatCurrency } from '../../utils/formatCurrency';
import priceOpinionsService from '../../api/services/priceOpinion';

interface Props {
  listing: listings;
}

const PriceOpinionModal = NiceModal.create<Props>((props) => {
  const modal = useModal();
  const { listing } = props;

  const formik = useFormik({
    initialValues: {
      price1: '',
      price2: '',
      price3: '',
      notes: '',
    },
    validationSchema: Yup.object({
      price1: Yup.number().required('Price 1 is required'),
      price2: Yup.number().required('Price 2 is required'),
      price3: Yup.number().required('Price 3 is required'),
      notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      await priceOpinionsService.create({
        ...values,
        price1: Number(values.price1),
        price2: Number(values.price2),
        price3: Number(values.price3),
        listings: {
          connect: {
            id: listing.id,
          },
        },
      });
      modal.remove();
    },
  });

  return (
    <Dialog open={modal.visible} onClose={modal.remove}>
      <DialogTitle>Create Price Opinion</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid2 container spacing={2}>
            <Grid2 xs={8}>
              <TextField
                label="Price 1"
                fullWidth
                name="price1"
                value={formik.values.price1.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}
                onChange={(e) => {
                  const value = e.target.value.replace(/,/g, '');
                  formik.setFieldValue('price1', value);
                }}
                error={formik.touched.price1 && Boolean(formik.errors.price1)}
                helperText={formik.touched.price1 && formik.errors.price1}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 xs={4} alignContent="center">
              <TextField
                label="Price per sq ft"
                fullWidth
                name="pricePerSqFt1"
                value={formatCurrency(
                  Number(formik.values.price1) / (listing.area ?? 1)
                )}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  const newPrice = Number(value) * (listing.area ?? 1);
                  formik.setFieldValue('price1', newPrice.toString());
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">/ sq ft</InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 xs={8}>
              <TextField
                label="Price 2"
                fullWidth
                name="price2"
                value={formik.values.price2.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}
                onChange={(e) => {
                  const value = e.target.value.replace(/,/g, '');
                  formik.setFieldValue('price2', value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                error={formik.touched.price2 && Boolean(formik.errors.price2)}
                helperText={formik.touched.price2 && formik.errors.price2}
              />
            </Grid2>
            <Grid2 xs={4} alignContent="center">
              <TextField
                label="Price per sq ft"
                fullWidth
                name="pricePerSqFt2"
                value={formatCurrency(
                  Number(formik.values.price2) / (listing.area ?? 1)
                )}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  const newPrice = Number(value) * (listing.area ?? 1);
                  formik.setFieldValue('price2', newPrice.toString());
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">/ sq ft</InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 xs={8}>
              <TextField
                label="Price 3"
                fullWidth
                name="price3"
                value={formik.values.price3.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}
                onChange={(e) => {
                  const value = e.target.value.replace(/,/g, '');
                  formik.setFieldValue('price3', value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                error={formik.touched.price3 && Boolean(formik.errors.price3)}
                helperText={formik.touched.price3 && formik.errors.price3}
              />
            </Grid2>
            <Grid2 xs={4} alignContent="center">
              <TextField
                label="Price per sq ft"
                fullWidth
                name="pricePerSqFt3"
                value={formatCurrency(
                  Number(formik.values.price3) / (listing.area ?? 1)
                )}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  const newPrice = Number(value) * (listing.area ?? 1);
                  formik.setFieldValue('price3', newPrice.toString());
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">/ sq ft</InputAdornment>
                  ),
                }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                label="Notes"
                multiline
                rows={4}
                fullWidth
                name="notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
              />
            </Grid2>
            <Grid2 xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Grid2>
          </Grid2>
        </form>
      </DialogContent>
    </Dialog>
  );
});

export default PriceOpinionModal;
