import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import { useEffect } from 'react';
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from '@mui/material';
import { Prisma } from '@prisma/client';
import { fetchOffers } from '../../../store/slices/offers';

const AdminOffers: React.FC = () => {
  const offers = useSelector((state: RootState) => state.offers.offers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOffers());
  }, []);

  return (
    <>
      <Typography variant="h6" pb={2}>
        Offers
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {offers.length > 0 && (
              <TableRow>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Buyer
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    Showing
                  </Typography>
                </TableCell>
                <TableCell>Other Times</TableCell>
                <TableCell>
                  <Typography variant="body2" fontWeight={600}>
                    User Type
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {offers.map(
              (offer: Prisma.offersGetPayload<{ include: { user: true } }>) => {
                return (
                  <TableRow key={offer.id}>
                    <TableCell>
                      <Stack direction="row" spacing={1}>
                        <Typography>
                          {offer.user.firstName} {offer.user.lastName}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{offer.user.type}</TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AdminOffers;
