import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { listingsWithUploads } from '../../api/services/listings';
import MyListing from '../../pages/Dashboard/Selling/Listing/MyListing';
import Image from '../../components/StreetViewImage/StreetViewImage';
import AdjustPriceModal from '../AdjustPriceModal/AdjustPriceModal';
import NiceModal from '@ebay/nice-modal-react';

interface ListingCardProps {
  listing: listingsWithUploads;
  showButtons?: boolean;
}

const ListingCard: React.FC<ListingCardProps> = ({
  listing,
  showButtons = true,
}) => {
  const navigate = useNavigate();
  const mainPhoto = listing?.uploads?.find((u) => u.main);

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} sm={2}>
        {mainPhoto ? (
          <img
            src={mainPhoto.url}
            width={'100%'}
            style={{ borderRadius: 4 }}
            alt="Listing"
          />
        ) : (
          <>
            <Image
              address={`${listing.address} ${listing.zip}`}
              height={'100%'}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sm={8}>
        <MyListing listing={listing} />
      </Grid>
      {showButtons && (
        <Grid
          item
          container
          xs={12}
          sm={2}
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          {listing.status !== 'APPROVED' && (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => navigate(`/listings/${listing?.id}/edit`)}
                fullWidth
              >
                Edit
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              onClick={() => navigate(`/listings/${listing?.id}`)}
              fullWidth
              disabled={listing.status !== 'APPROVED'}
            >
              View
            </Button>
          </Grid>
          {listing.status === 'APPROVED' && (
            <Grid item>
              <Button
                variant="contained"
                onClick={() => NiceModal.show(AdjustPriceModal, { listing })}
                fullWidth
              >
                Adjust Price
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ListingCard;
