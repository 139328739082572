import { styled } from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// const CustomToggleButtonGroup = styled(Carousel)(({ theme }) => ({
//   '.react-multiple-carousel__arrow': {
//     background: theme.palette.primary.main,
//   },
// }));

interface Props {
  photos: string[];
}

const PhotoCarouselV2: React.FC<Props> = ({ photos }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive} infinite={false}>
      {photos.map((photo, index) => (
        <img
          key={index}
          src={photo}
          alt={`photo-${index}`}
          style={{ width: '100%', borderRadius: 8, height: 200, padding: 4 }}
        />
      ))}
    </Carousel>
  );
};

export default PhotoCarouselV2;
