import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useEffect, useRef, useState } from 'react';
import usersService from '../../api/services/users';
import { useSnackbar } from 'notistack';
import { logout } from '../../store/slices/auth';
import { useAppDispatch } from '../../store/store';

const Settings: React.FC = () => {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const isInitialMount = useRef(true); // Track the initial mount
  const [settings, setSettings] = useState({
    smsEnabled: currentUser?.smsEnabled,
    emailEnabled: currentUser?.emailEnabled,
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const updateUser = async () => {
    if (currentUser) {
      const res = await usersService.patch(settings, currentUser.id);
      if (res.id) {
        enqueueSnackbar('Preferences updated successfully', {
          variant: 'success',
        });
      }
    }
  };

  useEffect(() => {
    // Skip the update on initial mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updateUser();
    }
  }, [settings]);

  const handleDeleteUser = async () => {
    if (currentUser) {
      await usersService.remove(currentUser.id);
      setOpenDeleteDialog(false);
      dispatch(logout());
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h6" fontWeight={500}>
        Settings
      </Typography>
      <Card>
        <Stack direction="column" spacing={1} m={2}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.smsEnabled}
                    onChange={handleCheckboxChange}
                    name="smsEnabled"
                  />
                }
                label="Enable SMS Notifications"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.emailEnabled}
                    onChange={handleCheckboxChange}
                    name="emailEnabled"
                  />
                }
                label="Enable Email Notifications"
              />
            </FormGroup>
          </FormControl>
          <Button
            variant="outlined"
            color="error"
            onClick={handleOpenDeleteDialog}
            sx={{ width: 'fit-content' }}
          >
            Delete Account
          </Button>
        </Stack>
      </Card>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to delete your account?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. All your data will be permanently
            deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteUser} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Settings;
