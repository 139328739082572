import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const selectUserId = (state: RootState) => state.auth.user?.id;
export const selectListings = (state: RootState) => state.listings.listings;

export const selectMyBuyingListings = createSelector(
  [selectListings, selectUserId],
  (listings, userId) =>
    listings
      .filter((listing) => listing.userId === userId)
      .filter((listing) => listing.type === 'BUYER')
);

export const selectMySellingListings = createSelector(
  [selectListings, selectUserId],
  (listings, userId) =>
    listings
      .filter((listing) => listing.userId === userId)
      .filter((listing) => listing.type === 'SELLER')
);
