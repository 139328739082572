import axiosInstance from '../api';

interface Data {
  listingId: string;
  leadPaint?: boolean;
  leadPaintExplain?: string;
  formId: number;
  offerId?: string;
  secondSellerName?: string;
  secondSellerEmail?: string;
}

const formsService = {
  post: async (data: Data) => {
    const res = await axiosInstance.post('/forms', data);
    return res.data;
  },
};

export default formsService;
