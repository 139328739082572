import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Prisma } from '@prisma/client';
import showingsService from '../../api/services/showings';

type ShowingWithListingsAndUser = Prisma.showingsGetPayload<{
  include: { user: true; listings: true };
}>;

interface State {
  showings: ShowingWithListingsAndUser[];
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  showings: [],
  loading: false,
  error: null,
};

export const fetchShowings = createAsyncThunk(
  'showings/fetch',
  async (_params, { rejectWithValue }) => {
    try {
      const clients = await showingsService.find();
      return clients;
    } catch (error) {
      return rejectWithValue('Failed to fetch showings');
    }
  }
);

const showingsSlice = createSlice({
  name: 'showings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShowings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchShowings.fulfilled,
        (state, action: PayloadAction<ShowingWithListingsAndUser[]>) => {
          state.loading = false;
          state.showings = action.payload;
        }
      )
      .addCase(fetchShowings.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default showingsSlice.reducer;
