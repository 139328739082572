import {
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  Button,
  ButtonGroup,
  ToggleButtonGroup,
  styled,
  ToggleButton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import _ from 'lodash';
import { useEffect, useState } from 'react';

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(0.375),
  '& .MuiToggleButton-root': {
    borderRadius: theme.spacing(2.5),
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    padding: theme.spacing(0.5, 2),
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const Product: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [type, setType] = useState('Seller');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Typography variant="h4" textAlign={'center'} sx={{ fontWeight: 700 }}>
        Our Product
      </Typography>
      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'center'}
        spacing={1}
        sx={{ mt: theme.spacing(1) }}
      >
        <Typography>I am a</Typography>
        <CustomToggleButtonGroup
          value={type}
          exclusive
          onChange={(e, value) => {
            if (value !== null) {
              setType(value);
              document
                .getElementById(_.toLower(value))
                ?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <ToggleButton value={'SELLER'} color="primary">
            <Typography variant="body1" fontWeight={600}>
              Seller
            </Typography>
          </ToggleButton>
          <ToggleButton value={'BUYER'} color="primary">
            <Typography variant="body1" fontWeight={600}>
              Buyer
            </Typography>
          </ToggleButton>
        </CustomToggleButtonGroup>
      </Stack>
      {/* <Typography variant="subtitle2" textAlign={'center'}>
        The experience of a traditional agent for a flat fee.
      </Typography> */}
      {/* <Typography variant="subtitle1" textAlign={'center'}>
        Get support from a real estate pro at any time.
      </Typography> */}
      <br />
      <Typography variant="h4" textAlign={'center'} id="seller">
        Selling
      </Typography>
      <Grid2 container>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              Create Listing
            </Typography>
          </Stack>
          {mobile && <img src={'/demo_3.png'} width="100%" />}
          <Typography>
            <ul>
              <li>Create your listing with our step-by-step guide.</li>
              <li>
                Include general and unique details of your home, included items
                in the sale.
              </li>
              <li>List any recent renovations to your home.</li>
              <li>
                Homepoint generates a listing description and lets you modify as
                you see fit.
              </li>
              <li>Choose date for professional photographs.</li>
              <li>
                Let Homepoint price your home or price on your own with our
                comparison tool.
              </li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/demo_3.png'} height={350} />
            </Grid2>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/demo_1.png'} height={350} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              Dashboard
            </Typography>
          </Stack>
          {mobile && <img src={'/demo_1.png'} width="100%" />}
          <Typography>
            <ul>
              <li>Organizes potential buyers in an easy to view list.</li>
              <li>
                See which buyers have active offers, showings, or messages.
              </li>
            </ul>
          </Typography>
        </Grid2>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              Offers
            </Typography>
          </Stack>
          {mobile && <img src={'/demo_2.png'} width="100%" />}
          <Typography>
            <ul>
              <li>Offers are displayed for easy comparison.</li>
              <li>
                Homepoint gives offers a score based on closing date, financing
                type, etc., to help compare offers.
              </li>
              <li>Estimated net proceeds are calculated.</li>
              <li>
                Able to act on each offer by accepting, countering, or
                declining.
              </li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/demo_2.png'} height={350} style={{ margin: -20 }} />
            </Grid2>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/demo_4.png'} height={350} style={{ margin: -20 }} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              Buyer Details
            </Typography>
          </Stack>
          {mobile && <img src={'/demo_4.png'} width="100%" />}
          <Typography>
            <ul>
              <b style={{ marginLeft: -20 }}>Buyers can</b>
              <li>Schedule showings and submit feedback</li>
              <li>Create and submit offers</li>
              <li>Upload documents</li>
              <li>Send messages for questions, etc.</li>
            </ul>
          </Typography>
        </Grid2>
      </Grid2>
      <Typography variant="h4" textAlign={'center'} id="buyer">
        Buying
      </Typography>
      <Grid2 container>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              A new way for home buying
            </Typography>
          </Stack>
          {mobile && <img src={'/Product1.png'} width="100%" />}
          <Typography>
            <ul>
              <li>No commitment, on demand home tours</li>
              <li>
                Prep offers and Homepoint consults on them before submitting to
                the seller
              </li>
              <li>
                Support from offer to close with inspections, title, and
                anything else you need.
              </li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/Product1.png'} height={350} style={{ margin: -20 }} />
            </Grid2>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/Product2.png'} height={350} style={{ margin: -20 }} />
            </Grid2>
          </>
        )}
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              Schedule Tours
            </Typography>
          </Stack>
          {mobile && <img src={'/Product2.png'} width="100%" />}
          <Typography>
            <ul>
              <li>
                Showings are with an agent whose only job is to show you the
                home, they receive no commission for the showing.
              </li>
              <li>
                Tours are $50 per showing and these fees are refunded if you
                close on a home.
              </li>
              <li>
                Add any home that is for sale and schedule a tour on demand.
              </li>
            </ul>
          </Typography>
        </Grid2>
        <Grid2 xs={mobile ? 12 : 6} mt={2} justifyContent={'center'}>
          <Stack direction="column" ml={2.5}>
            <Typography variant="h5" fontWeight={600}>
              Make Offers
            </Typography>
          </Stack>
          {mobile && <img src={'/Product2.png'} width="100%" />}
          <Typography>
            <ul>
              <li>Create and prep offers with our step-by-step guide.</li>
              <li>
                Homepoint reviews your offer and consults with you on
                adjustments before sending to the seller.
              </li>
              <li>
                If your offer is accepted, Homepoint provides support through
                the transaction process all the way to closing.
              </li>
            </ul>
          </Typography>
        </Grid2>
        {!mobile && (
          <>
            <Grid2 xs={6} textAlign={'center'}>
              <img src={'/Product3.png'} height={350} style={{ margin: -20 }} />
            </Grid2>
          </>
        )}
      </Grid2>
    </>
  );
};

export default Product;
