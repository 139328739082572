import axiosInstance from '../api';

const autocompleteService = {
  find: async (input: string): Promise<any> => {
    const response = await axiosInstance.get('/autocomplete', {
      params: {
        search: input,
      },
    });
    return response.data;
  },
};

export default autocompleteService;
