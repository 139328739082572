import axiosInstance from '../api';

const forgotPasswordService = {
  patch: async ({ email }: { email: string }): Promise<any> => {
    const response = await axiosInstance.patch('/forgot-password', { email });
    return response.data;
  },
  post: async ({
    token,
    newPassword,
  }: {
    token: string;
    newPassword: string;
  }): Promise<any> => {
    const response = await axiosInstance.post('/forgot-password', {
      token,
      newPassword,
    });
    return response.data;
  },
};

export default forgotPasswordService;
