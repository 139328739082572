import { Button, Card, CardContent, Stack } from '@mui/material';
import AutoComplete from '../../Listings/Autocomplete';
import { useEffect, useState } from 'react';
import { AddressSelection } from '../../../types/AddressSelection';
import CompsTable from '../../EditListing/forms/PricingForm/CompsTable';
import listingsService from '../../../api/services/listings';
import compsService, { compsWithEstPrice } from '../../../api/services/comps';
// eslint-disable-next-line max-len
import PriceOpinionModal from '../../../components/PriceOpinionModal/PriceOpinionModal';
import { listings } from '@prisma/client';
import NiceModal from '@ebay/nice-modal-react';

const Comps = () => {
  const [address, setAddress] = useState<AddressSelection | null>(null);
  const [comps, setComps] = useState<compsWithEstPrice | null>(null);
  const [listing, setListing] = useState<listings | null>(null);

  const addComp = async () => {
    if (!address?.id || !listing?.id) return;
    await compsService.create({
      refId: address?.id,
      listingId: listing?.id,
    });
    setAddress(null);
    getComps();
  };

  const getListing = async () => {
    const res = await listingsService.find({
      params: {
        where: {
          refId: address?.id,
        },
      },
    });
    setListing(res[0]);
    setAddress(null);
    return res;
  };

  const getComps = async () => {
    const res = await compsService.find(listing?.id || '');
    setComps(res);
  };

  useEffect(() => {
    if (listing) {
      getComps();
    }
  }, [listing]);

  useEffect(() => {
    if (address?.id && !listing?.id) {
      getListing();
    }
  }, [address]);

  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="primary" onClick={addComp}>
              Add Comp
            </Button>
            {listing && (
              <Button
                variant="contained"
                color="primary"
                sx={{ whiteSpace: 'nowrap' }}
                onClick={() =>
                  NiceModal.show(PriceOpinionModal, {
                    listing,
                  })
                }
              >
                Create Price Opinion
              </Button>
            )}
          </Stack>
          <AutoComplete
            handleChange={(property) => setAddress(property)}
            property={address}
          />
          <CompsTable
            comps={comps?.comps || []}
            showDelete
            onDelete={getComps}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Comps;
